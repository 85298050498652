import Model from './Model';
import {User} from 'models';
import IVisit from './interfaces/IVisit';
export default class Visit extends Model implements IVisit {

  static type = 'visits';

  text: string;
  date: string;
  author?: User;
  placeId?: number;
  typology?: string;

  get typology_name(){
    if(this.typology != undefined && Visit.typologies.some((el) => el.id == this.typology)){
      return Visit.typologies.filter((el => el.id == this.typology))[0].name;
    }
  }

  static typologies: Array<{id: string, name: string}> = [
    { id: 'appointment', name: "Visita in presenza" },
    { id: 'phone_call', name: "Telefonata" },
  ];
}
