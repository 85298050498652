
































































































import ClickOutside from 'directives/ClickOutside';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({ directives: { ClickOutside } })
export default class ModelSelect extends Vue {
  @Prop() value!: any;
  @Prop() id: string | number;
  @Prop() classes: string;
  @Prop({ default: false }) required: boolean;
  @Prop({ default: 'Cerca e seleziona' }) title: string;
  @Prop() formClass: string;
  @Prop({ default: true }) showTitle: boolean;
  @Prop({ default: `(min. %d caratteri)` }) placeholder: string;
  @Prop({ default: 3 }) minSearchLength: number;
  @Prop({ default: false }) actsLikeFilter: boolean;
  @Prop({ default: 350 }) inputTimeout: number;
  @Prop({ default: 'name' }) displayProperty: string;
  @Prop({ default: true }) canCreate: boolean;
  @Prop({ default: () => [] }) defaultOptions: any[] | null;
  @Prop({ default: true }) canSearch: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop() customDisplay: string;

  private dropdownVisible = false;
  private term: string | null = null;
  private loading = false;
  private searching = false;
  private timeout = null;
  private options = [];
  private selected = this.value;
  private stateChanger = {};
  private searchActive = false;

  @Watch('value')
  onValueChanged(value) {
    this.selected = value;
  }

  @Watch('id')
  onIdChanged(newValue, oldValue) {
    if (!newValue && oldValue) this.selected = null;
  }

  @Watch('dropdownVisible')
  onDropdownVisibleChanged(value) {
    if (value) {
      this.$nextTick(() => {
        if (this.$refs.input) {
          (this.$refs.input as HTMLInputElement).focus();
        }
      });
    } else {
      this.resetSearch();
    }
  }

  @Watch('defaultOptions', { immediate: true, deep: true })
  onDefaultOptionsChanged(newValue, oldValue) {
    if (!this.value && this.id) {
      if (this.defaultOptions.length) {
        this.options = this.defaultOptions;
        this.selected = this.options.find((o) => o.id == this.id);
      }
    }
  }

  get display() {
    return this.selected ? this.selected[this.displayProperty] : null;
  }

  get formattedPlaceholder() {
    return this.placeholder.replace('%d', this.minSearchLength + '');
  }

  get params() {
    return {
      term: this.term,
    };
  }

  get isSearchable() {
    if (!this.term) {
      return false;
    }

    return this.term.length >= this.minSearchLength || !this.term.length;
  }

  created() {
    this.stateChanger = {
      searching: () => {
        this.loading = true;
      },
      searched: (options) => {
        this.options = options;
        this.searching = true;
        this.loading = false;
      },
      error: () => {
        // TODO: error handling implementation
      },
    };

    if (this.defaultOptions.length) {
      this.options = this.defaultOptions;
      this.searching = true;
    }
  }

  toggleDropdown() {
    if (!this.disabled) this.dropdownVisible = !this.dropdownVisible;
  }

  hideDropdown() {
    this.dropdownVisible = false;
  }

  resetSearch() {
    if (this.defaultOptions.length) {
      this.options = this.defaultOptions;
      this.searching = true;
    } else {
      this.options = [];
      this.searching = false;
    }

    this.loading = false;
    this.term = null;
    this.$emit('search', this.term, this.stateChanger);
  }

  onKeyUp(event) {
    if (this.isSearchable) {
      this.searchActive = true;
      this.emitSearch(this.term);
    } else if (this.searchActive) {
      this.searchActive = false;
      this.emitSearch('');
    }
  }

  emitSearch(term: string) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.$emit('search', term, this.stateChanger);
    }, this.inputTimeout);
  }

  onSelect(option) {
    this.selected = option;
    this.hideDropdown();

    this.$emit('input', option);
    this.$emit('selected', option);
  }

  onNew() {
    this.$emit('new');
  }
}
