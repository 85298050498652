import Vue from 'vue';

export function showToast(message, config: {position?: string, type?: string, duration?: number} = {position: 'bottom-right', type: 'success', duration: 1000}) {

  Vue.toasted.show(message, {
    position: config.position,
    type: config.type,
    duration: config.duration,
    keepOnHover: true
  } as any);
}
