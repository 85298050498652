








import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import { Place } from 'models';
import VisitList from 'components/Visit/List.vue';
import VisitsMixin from 'mixins/http/VisitsMixin';

@Component({ components: { InlineEditor, VisitList } })
export default class PlaceVisits extends Mixins(VisitsMixin) {
  @Prop({ required: true })
  place: Place;

  onDataUpdated(data) {
    this.$emit('updated', data);
  }
}
