



















import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';

@Component({ components: { Modal } })
export default class ConfirmModal extends Vue {

  @Prop()
  title?: string;

  @Prop()
  content?: string;

  show() {
      const modal = this.$refs.modal as any;
      modal.show();
    }

    hide() {
      const modal = this.$refs.modal as any;
      modal.hide();
    }

  onCanceled() {
    this.hide();
    this.$emit('canceled');
  }

  onConfirmed() {
    this.hide();
    this.$emit('confirmed');
  }

}
