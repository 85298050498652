import { Component, Mixins } from 'vue-property-decorator';
import RequestsMixin from 'mixins/http/RequestsMixin';

@Component
export default class VisitsMixin extends Mixins(RequestsMixin) {

  destroyVisit(id, tag) {
    return this.$httpDelete(this.$urlFor('api_v1_visit', id ), tag);
  }

  createVisit(data: any, tag) {
    return this.$httpPost(this.$urlFor('api_v1_visits'), tag, data);
  }

  getVisits(data: any, tag) {
    return this.$httpGet(this.$urlFor('api_v1_visits', data), tag);
  }

  updateVisit(data: any, tag) {
     return this.$httpPut(this.$urlFor('api_v1_visit', { id: data.id }), tag, data);
  }
}
