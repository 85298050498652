

import { Component, Vue } from 'vue-property-decorator';
import { User, Place } from 'models';

@Component
export default class PlaceAuthorizationMixin extends Vue {
  canDeletePlace(user: User, place: Place) {
    if (!place) {
      return false;
    }

    const hasCreatedResource = user?.id === place?.user?.id;

    if (user?.isAgent) {
      return false;
    }

    if (user.isExecutive) {
      return hasCreatedResource && place.isOneOf(['draft', 'pending']);
    }

    return user.isAdmin || user.isManager;
  }

  canSharePlace(user: User, place: Place) {
    return (user?.isAgent || user?.isManager) && this.placeOwned(user, place);
  }

  canCompletePlace(user: User, place: Place) {

    if (!place?.isScheduled || !place?.order?.installationDate || new Date(place?.order?.installationDate) > new Date()) {
      return false;
    }

    return user?.isManager || user?.isTechnician;
  }

  canSchedulePlace(user: User, place: Place) {

    return user?.isTechnician && place?.isCustomer;
  }

  canPromotePlace(user: User, place: Place) {

    return user?.isManager && this.placeOwned(user, place) && place.isLead;
  }

  canDownloadPlaceReport(user: User, place: Place) {
    return place.isOneOf(['scheduled', 'completed']);
  }

  canRejectPlace(user: User, place: Place) {

    return user?.isManager && place?.isProspect && !this.placeOwned(user, place);
  }

  canAcceptPlace(user: User, place: Place) {

    return user?.isManager && place?.isProspect;
  }

  canChangeDate(user: User, place: Place) {

    if (!place.isOneOf(['customer', 'scheduled'])) return false;

    if (user?.isAgent) {
      return this.placeOwned(user, place);
    }

    return user?.isManager;
  }

  canChangeSerialNumber(user: User, place: Place) {

    if (!user?.isTechnician) return false;

    return place?.isCustomer;
  }

  canChangeModel(user: User, place: Place) {

    if (!user?.isTechnician) return false;

    return place?.isCustomer;
  }

  placeOwned(user: User, place: Place) {
    return user?.id === place?.agent?.id;
  }

  canCandidatePlace(user: User, place: Place) {
    if (!user?.isAgent) return false;

    return (place?.isLead || place?.isRejected) && this.placeOwned(user, place);
  }

  canDiscardPlace(user: User, place: Place) {

    if (place.isCompleted) {
      return false;
    }
    return this.placeOwned(user, place) || user?.isManager;
  }

  canAssignPlace(user: User, place: Place) {
    if (user?.isAgent || user?.isTechnician) {
      return false;
    }

    if (user.isExecutive) {
      return place?.isDraft;
    }

    return place?.isPending;
  }

  canNotifyPlace(user: User, place: Place) {
    if (user?.isAgent || user?.isTechnician) {
      return false;
    }

    return place?.isDraft;
  }

  canReassignPlace(user: User, place: Place) {
    const allowedRoles = ["admin", "manager"];
    const allowedPlaceStates = ["lead", "prospect", "rejected", "discarded", "customer", "scheduled", "completed"];
    return user?.isOneOf(allowedRoles) && place?.isOneOf(allowedPlaceStates);
  }

  currentUserIsManager(user: User){
    return user?.isManager;
  }
}
