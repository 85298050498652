





























import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import Notifications from 'components/Notification/List.vue';
import ClickOutside from 'directives/ClickOutside';
import { RequestData } from '/store/types/RequestStore';
import ActivityRecipientsMixin from 'mixins/http/ActivityRecipientsMixin';
import { RegisterHttp } from 'utils/Decorators';
import { Getter } from 'vuex-class';
import Icon from 'components/Icon.vue';

@Component({
  components: {
    Icon,
    Notifications
  },
  directives: {
    ClickOutside
  }
})
export default class NotificationsDropdown extends Mixins(ActivityRecipientsMixin) {
  @Getter currentUser;

  open: boolean = false;
  unreadCount: number = 0;

  unreadNotificationsCount(val) {
    this.unreadCount = val;
  }

  decreaseUnreadCount() {
    this.unreadCount -= 1;
  }

  onOutsideClick() {
    if (this.open) this.open = false;
  }
}
