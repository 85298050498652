import {User, Client} from 'models';
import Model from './Model';

export default class Order extends Model {
  static type = 'clientImports';

  status?: string;
  fileUrl?: string;
  user: User;
  clients: Client[];
}
