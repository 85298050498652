


















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import { RequestData } from '/store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import PlaceParametersMixin from 'mixins/http/PlaceParametersMixin';
import { Competitor } from '../../models';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class CompetitorSelect extends Mixins(
  PlaceParametersMixin,
  ObjectPathMixin
) {
  static readonly getCompetitorsTag = 'get_competitors';
  @RegisterHttp(CompetitorSelect.getCompetitorsTag) http: RequestData;

  @Prop() idSelected?: string | number;
  @Prop() params;
  @Prop() dataKeys?: string;
  @Prop({ default: false }) disabled?: boolean;

  private items: any = null;

  get queryParams() {
    return { per: 1000, ...this.params };
  }

  mounted() {
    this.loadItems();
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
  }

  loadItems() {
    this.getPlaceParameters(
      Competitor,
      this.queryParams,
      CompetitorSelect.getCompetitorsTag
    ).then(
      resp => {
        this.items = resp;
      }
    );
  }
}
