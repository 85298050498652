


















import { Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import { RequestData } from 'store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import PlaceParametersMixin from 'mixins/http/PlaceParametersMixin';
import { Blend } from 'models';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class BlendSelect extends Mixins(
  PlaceParametersMixin,
  ObjectPathMixin
) {
  static readonly getBlendsTag = 'get_blends';
  @RegisterHttp(BlendSelect.getBlendsTag) http: RequestData;

  @Prop() idSelected?: string | number;
  @Prop() params;
  @Prop() dataKeys?: string;
  @Prop({ default: false }) disabled?: boolean;

  items: any = null;

  get queryParams() {
    return { per: 1000, ...this.params };
  }

  mounted() {
    this.loadItems();
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
    this.$emit('selected', data);
  }

  loadItems() {
    this.getPlaceParameters(
      Blend,
      this.queryParams,
      BlendSelect.getBlendsTag
    ).then(
      resp => {
        this.items = resp.filter((element) => element.disabled === false);
      }
    );
  }
}
