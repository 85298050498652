




























































































import ConfirmModal from 'components/ConfirmModal.vue';
import DisplayErrors from 'components/DisplayErrors.vue';
import Icon from 'components/Icon.vue';
import Accept from 'components/Place/Accept.vue';
import AssignAgent from 'components/Place/AssignAgent.vue';
import PlaceCompleteModal from 'components/Place/CompleteModal.vue';
import PlaceForm from 'components/Place/Form.vue';
import InstallationDateBanner from 'components/Place/InstallationDateBanner.vue';
import Reject from 'components/Place/Reject.vue';
import ShareToAgent from 'components/Place/ShareToAgent.vue';
import StatusBar from 'components/Place/StatusBar.vue';
import WaitForSubmission from 'components/Place/WaitForSubmission.vue';
import PlaceAuthorizationMixin from 'mixins/PlaceAuthorizationMixin';
import PlaceSharingsMixin from 'mixins/http/PlaceSharingsMixin';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { Place } from 'models';
import { RequestData } from 'store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import Page from 'views/Page.vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { showToast } from '../../utils/Toast';

@Component({
  components: {
    Page,
    Reject,
    ShareToAgent,
    StatusBar,
    InstallationDateBanner,
    PlaceForm,
    Icon,
    Accept,
    AssignAgent,
    WaitForSubmission,
    DisplayErrors,
    ConfirmModal,
    PlaceCompleteModal
  }
})
export default class PlaceEdit extends Mixins(
  PlacesMixin,
  PlaceAuthorizationMixin,
  PlaceSharingsMixin
) {
  @Getter currentUser;
  @Getter currentPlace?: Place;
  @Action('http/register') registerCall;
  @Action setCurrentPlace;

  static readonly getPlaceTag = 'get_place';
  @RegisterHttp(PlaceEdit.getPlaceTag) getPlaceRequest: RequestData;

  static readonly assignAgentTag = 'assign_agent';
  @RegisterHttp(PlaceEdit.assignAgentTag) assignAgentRequest: RequestData;

 static readonly updatePlaceTag = 'update_place';
  @RegisterHttp(PlaceEdit.updatePlaceTag) updatePlaceRequest: RequestData;

  static readonly deletePlaceTag = 'delete_place';
  @RegisterHttp(PlaceEdit.deletePlaceTag) deletePlaceRequest: RequestData;

  static readonly discardPlaceTag = 'discard_place';
  @RegisterHttp(PlaceEdit.discardPlaceTag) discardPlaceRequest: RequestData;

  static readonly schedulePlaceTag = 'schedule_place';
  @RegisterHttp(PlaceEdit.schedulePlaceTag) schedulePlaceRequest: RequestData;

  static readonly candidatePlaceTag = 'candidate_place';
  static readonly downloadContractPreviewTag = 'download_contract_preview';

  static readonly completePlaceTag = 'complete_place';

  @Watch('$route.params.place_id', { immediate: true, deep: true })
  onUrlChange(newVal: any) {
    this.loadResource();
  }

  private resource?: Place = null;
  private assignAgentModalName = 'assign_agent_modal';
  private shareToAgentModalName = 'share_to_agent_modal';
  private confirmDeletionModalName = 'confirm_deletion_modal';
  private confirmScheduleModalName = 'confirm_schedule_modal';
  private confirmCompletionModalName = 'confirm_completion_modal';
  private rejectPlaceModalName = 'reject_modal';
  private acceptPlaceModalName = 'accept_modal';
  private waitForSubmissionModalName = 'wait_for_submission_modal';
  private confirmDiscardmentModalName = 'confirm_discardment_modal';
  private confirmDeletionModalTitle = 'Elimina contatto';
  private confirmDeletionModalContent = 'Sei sicuro di voler eliminare il contatto? Il contatto verrà completamente cancellato e non sarà più recuperabile';
  private confirmDiscardmentModalTitle = 'Abbandona contatto';
  private confirmDiscardmentModalContent = 'Sei sicuro di voler abbandonare il contatto? Il contatto sarà ancora reperibile ma passerà in stato ABBANDONATO';
  private confirmScheduleModalTitle = 'Conferma dati';
  private confirmScheduleModalContent = 'Una volta confermati, questi dati non potranno più essere modificati e il contatto passerà in stato PROGRAMMATO';
  private confirmCandidatePlaceModalName = 'confirm_candidate_place';
  private confirmCandidatePlaceModalTitle = 'Inviare alla direzione?';
  private confirmCandidatePlaceModalContent = 'Sei sicuro di voler inviare alla direzione il contatto? Verifica i dati inseriti e se sei certo di voler continuare premi "Conferma"';

  mounted() {
    this.loadResource();
  }

  get showInstallationDateBanner() {
    return this.currentPlace?.isOneOf(['customer', 'scheduled']);
  }

  get ownsPlace() {
    return this.currentUser?.isAgent && this.resource?.agent?.id === this.currentUser.id;
  }
  deletePlace() {
    this.destroyPlace(this.currentPlace.id, PlaceEdit.deletePlaceTag).then(
      resp => {
        this.$router.go(-1);
      }
    );
  }

  discardPlace() {
    this.discard({id: this.currentPlace.id}, PlaceEdit.discardPlaceTag)
    .then(resp => {
      showToast('Contatto abbandonato con successo!');
      this.setCurrentPlace(resp);
    }, err => {
      showToast('Non è stato possibile abbandonare il contatto!', {position: 'bottom-right', type: 'error', duration: 1000});
    });
  }

  schedulePlace() {
    this.schedule({id: this.currentPlace.id}, PlaceEdit.schedulePlaceTag)
    .then(resp => {
      showToast('Installazione presso il contatto programmata con successo!');
      this.setCurrentPlace(resp);
    }, err => {
      showToast('Non è stato possibile programmare l\'installazione presso il contatto!', {position: 'bottom-right', type: 'error', duration: 1000});
    });
  }

  onPlaceRejected() {
    this.loadResource();
  }

  onPlaceAccepted() {
    this.loadResource();
  }

  get updatePlaceTag() {
    return PlaceEdit.updatePlaceTag;
  }

  get deletePlaceTag() {
    return PlaceEdit.deletePlaceTag;
  }

  candidatePlaceTag() {
    return `${PlaceEdit.candidatePlaceTag}_${this.resource.id}`;
  }

  downloadContractPreviewTag() {
    return `${PlaceEdit.downloadContractPreviewTag}_${this.resource.id}`;
  }

  completePlaceTag() {
   return `${PlaceEdit.completePlaceTag}_${this.resource.id}`;
  }

  promotePlace() {
    this.candidatePlace().then(resp => {
      this.showModal(this.acceptPlaceModalName, true);
    });
  }

  completePlace(installationNotes: string) {
      this.complete({id: this.currentPlace.id, installationNotes }, this.completePlaceTag()).then(
        resp => {
          this.resource = resp;
          showToast('Il contatto è stato completato con successo!');
          this.setCurrentPlace(resp);
        },
        err => {
          showToast('Non è stato possibile completare il contatto', {position: 'bottom-right', type: 'error', duration: 1000});
        }
    );
  }

  candidatePlace(): Promise<Place|undefined> {
    let data = {
      id: this.resource.id
    };

    let promise = this.resource.isRejected ? this.recandidate(data, this.candidatePlaceTag()) :  this.candidate(data, this.candidatePlaceTag());
    promise.then(
      resp => {
        this.resource = resp;
        this.setCurrentPlace(resp);
        showToast('Contatto inviato alla Direzione con successo!');
        this.showModal(this.waitForSubmissionModalName, true);
      },
      err => {
        showToast('Non è stato possibile segnalare il contatto alla Direzione', {position: 'bottom-right', type: 'error', duration: 1000});
      }
    );

    return promise;
  }

  assignAgent(agent) {
    const data = {
      id: this.resource.id,
      agent_id: agent.id
    };

    let promise: Promise<any> | null = null;

    if (this.canReassign) {
      promise = this.reassign(data, PlaceEdit.assignAgentTag);
    } else {
      promise = this.assign(data, PlaceEdit.assignAgentTag);
    }

    promise.then(
      resp => {
        this.loadResource();
        showToast(
          `${agent.fullname()} assegnato a ${this.resource.name} correttamente`
        );
      }
    );
  }

  get disabled() {
    if (!this.currentPlace || !this.currentUser) {
      return true;
    }

    if (this.currentUser.isAgent) {
      const ownsPlace = this.currentPlace?.agent?.id === this.currentUser?.id;
      const isAllowedStatus =  this.currentPlace?.isLead || this.currentPlace?.isRejected;
      return !(ownsPlace && isAllowedStatus);
    } else if (this.currentUser?.isTechnician) {
      return true;
    } else if ( this.currentUser?.isExecutive) {
      return !this.currentPlace?.isDraft;
    } else if ( this.currentUser.isManager) {
      return !(this.currentPlace?.isDraft || this.currentPlace?.isPending || this.currentPlace?.isLead || this.currentPlace?.isRejected);
    } else {
      return false;
    }
  }

  get canReassign() {
    return this.canReassignPlace(this.currentUser, this.resource);
  }

  showModal(name, show) {
    const modal = this.$refs[name] as any;
    show ? modal.show() : modal.hide();
  }

  addNestedAttributesIds(data) {
    if (data.contact && this.resource.contact.id)
      data.contact.id = this.resource.contact.id;
    if (data.company && this.resource.company.id) {
      data.company.id = this.resource.company.id;
      if (data.company.contact && this.resource.company.contact.id)
        data.company.contact.id = this.resource.company.contact.id;
    }
  }

  update(data) {
    this.addNestedAttributesIds(data);
    let params = {
      id: this.resource.id,
      ...data
    };
    this.updatePlace(params, PlaceForm.updatePlaceTag).then(
      resp => {
        this.resource = resp;
        this.setCurrentPlace(this.resource);
      }
    );
  }

  loadResource() {
    const id = this.$route.params.place_id;
    this.getPlace(id, PlaceEdit.getPlaceTag).then(
      resp => {
        this.resource = resp;
        this.setCurrentPlace(resp);
        this.registerCall(this.candidatePlaceTag());
        this.registerCall(this.downloadContractPreviewTag());
        this.registerCall(this.completePlaceTag());
      }
    );
  }

  beforeDestroy() {
    this.setCurrentPlace(null);
  }

  notify() {
    this.notifyPlace(this.resource, PlaceEdit.getPlaceTag).then(
      resp => {
        showToast(`Contatto segnalato alla direzione con successo!`);
        this.resource = resp;
        this.setCurrentPlace(resp);
      }
    );
  }
}
