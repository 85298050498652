







import { Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import ObjectPathMixin from 'mixins/ObjectPathMixin';
import { Visit } from 'models';

@Component({ components: { ModelSelect } })
export default class TypologySelect extends Mixins(ObjectPathMixin) {
  @Prop() idSelected?: string | number;
  @Prop() dataKeys?: Array<string>;
  @Prop() classes?: string;
  @Prop({ default: false }) actsLikeFilter?: boolean;

  items: Array<{id: string, name: string}> = Visit.typologies;
  id: any = this.idSelected;

  mounted() {
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
  }
}
