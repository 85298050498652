






import {Vue, Component, Mixins} from 'vue-property-decorator';
import ActivityRecipientsMixin from 'mixins/http/ActivityRecipientsMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';

@Component
export default class ActivityRecipientList extends Mixins(ActivityRecipientsMixin) {

  static readonly getActivityRecipientsTag = 'get_activity_recipients';

  @RegisterHttp(ActivityRecipientList.getActivityRecipientsTag) getActivityRecipientsRequest: RequestData;

  items = null;

  mounted() {
    this.loadNotifications();
  }

  get status() {
    return '';
  }

  loadNotifications() {

    this.getActivityRecipients({}, ActivityRecipientList.getActivityRecipientsTag)
    .then( resp => {
      this.items = resp;
    }, err => {

    });
  }
}
