

















































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { User } from 'models';
import UsersMixin from 'mixins/http/UsersMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import ModelSelect from 'components/ModelSelect.vue';
import DataTable from '@monade/vue-components/src/components/DataTable.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
import SearchBar from 'components/SearchBar.vue';
import Paginator from '@monade/vue-components/src/components/Paginator.vue';

@Component({
  components: { Modal, ModelSelect, Loader, DataTable, SearchBar, Paginator }
})
export default class AssignAgent extends Mixins(UsersMixin) {
  static readonly getAgentsTag = 'get_agents';
  static readonly assignAgentTag = 'assign_agent';
  private selectedAgent?: User = null;
  private agents?: Array<User> = Array();
  private page = 1;
  private columns = [
    { name: 'agent', label: 'Agente' },
    { name: 'action', label: '' }
  ];

  @RegisterHttp(AssignAgent.getAgentsTag) getAgentsRequest: RequestData;
  @RegisterHttp(AssignAgent.assignAgentTag) assignAgentRequest: RequestData;

  show() {
    this.loadAgents();
    const modal = this.$refs.modal as any;
    modal.show();
  }

  onPageChange(page) {
    this.page = page;
    this.loadAgents();
  }

  onAgentSelected(agent) {
    this.selectedAgent = agent;
  }

  onSearch(search) {
    this.page = 1;
    this.loadAgents({ term: search });
  }

  isSelected(agent) {
    return this.selectedAgent && agent.id === this.selectedAgent.id;
  }

  hide() {
    const modal = this.$refs.modal as any;
    modal.hide();
  }

  get params() {
    let data: any = {
      page: this.page,
      filter: {
        role: ['agent', 'manager']
      }
    };

    return data;
  }

  loadAgents(searchParams: any = {}) {
    let params = this.params;

    if (searchParams) {
      params.filter.term = searchParams.term;
    }

    this.getUsers(params, AssignAgent.getAgentsTag).then(
      resp => {
        this.agents = resp;
      }
    );
  }

  onConfirm() {
    this.hide();
    this.$emit('success', this.selectedAgent);
  }

  onCancel() {
    this.hide();
    this.$emit('canceled');
  }
}
