






import { Vue, Component, Prop } from 'vue-property-decorator';
import CheckCircle from 'components/Icon/CheckCircle.vue';
import Phone from 'components/Icon/Phone.vue';
import AccountOutline from 'components/Icon/AccountOutline.vue';
import AccountOutlineBig from 'components/Icon/AccountOutlineBig.vue';
import Pin from 'components/Icon/Pin.vue';
import Share from 'components/Icon/Share.vue';
import Search from 'components/Icon/Search.vue';
import Moon from 'components/Icon/Moon.vue';
import Download from 'components/Icon/Download.vue';
import Shop from 'components/Icon/Shop.vue';
import FeatherArrowUpCircle from 'components/Icon/FeatherArrowUpCircle.vue';
import FeatherArrowRightCircle from 'components/Icon/FeatherArrowRightCircle.vue';
import FeatherArrowLeftCircle from 'components/Icon/FeatherArrowLeftCircle.vue';
import Key from 'components/Icon/Key.vue';
import Group from 'components/Icon/Group.vue';
import CreditCard from 'components/Icon/CreditCard.vue';
import File from 'components/Icon/File.vue';
import Coffee from 'components/Icon/Coffee.vue';
import CoffeeBean from 'components/Icon/CoffeeBean.vue';
import CoffeeMachine from 'components/Icon/CoffeeMachine.vue';
import PlusCircle from 'components/Icon/PlusCircle.vue';
import MinusCircle from 'components/Icon/MinusCircle.vue';
import CloseCircle from 'components/Icon/CloseCircle.vue';
import Alert from 'components/Icon/Alert.vue';
import Bell from 'components/Icon/Bell.vue';
import Dots from 'components/Icon/Dots.vue';
import Edit from 'components/Icon/Edit.vue';
import Exit from 'components/Icon/Exit.vue';
import FeatherCheckCircle from 'components/Icon/FeatherCheckCircle.vue';
import Close from 'components/Icon/Close.vue';
import Calendar from 'components/Icon/Calendar.vue';
import Trash from 'components/Icon/Trash.vue';
import Contract from 'components/Icon/Contract.vue';
import FeatherCircle from 'components/Icon/FeatherCircle.vue';
import Youtube from 'components/Icon/Youtube.vue';

@Component({
  components: {
    CloseCircle,
    Calendar,
    Contract,
    Dots,
    Close,
    Download,
    Bell,
    Edit,
    Exit,
    Trash,
    MinusCircle,
    FeatherCheckCircle,
    PlusCircle,
    Alert,
    AccountOutlineBig,
    FeatherArrowRightCircle,
    CheckCircle,
    AccountOutline,
    Pin,
    Phone,
    Share,
    Search,
    Moon,
    Shop,
    CreditCard,
    File,
    Coffee,
    CoffeeBean,
    CoffeeMachine,
    FeatherArrowUpCircle,
    Key,
    Group,
    FeatherArrowLeftCircle,
    FeatherCircle,
    Youtube,
  }
})
export default class Icon extends Vue {

  @Prop({ required: true })
  name: string;

  @Prop({ default: 'md' })
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

  get sizeClass() {
    return `icon-${this.size}`;
  }
}
