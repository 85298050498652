

























import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';

@Component({ components: { Modal } })
export default class PlaceCompleteModal extends Vue {
  @Ref() readonly modal!: Modal;

  private installationNotes: string | null = null;

  show() {
      this.modal.show();
    }

    hide() {
      this.modal.hide();
    }

  onCanceled() {
    this.hide();
    this.$emit('canceled');
  }

  onConfirmed() {
    this.hide();
    this.$emit('confirmed', this.installationNotes);
  }

}
