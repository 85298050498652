


















import { Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class CustomSelectSelect extends Mixins(ObjectPathMixin) {
  @Prop() idSelected?: string | number;
  @Prop() params;
  @Prop() dataKeys?: string;
  @Prop() placeholder: string;
  @Prop({default: false}) disabled?: boolean;
  @Prop() items : Array<{ name: string; id: number }>;

  mounted() {
  }

  outputParams(data) {
   return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
  }
}
