





































































import { Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import PlaceData from 'components/Place/Form/PlaceData.vue';
import Icon from 'components/Icon.vue';
import PlaceContactData from 'components/Place/Form/PlaceContactData.vue';
import PlaceNotes from 'components/Place/Form/PlaceNotes.vue';
import PlaceVisits from 'components/Place/Form/PlaceVisits.vue';
import PlaceOrder from 'components/Place/Form/PlaceOrder.vue';
import { Place, User } from 'models';
import PlacesMixin from '../../mixins/http/PlacesMixin';
import {Getter} from 'vuex-class';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import PlaceEdit from 'views/Place/Edit.vue';
import IValidationError from 'models/interfaces/IValidationError';

@Component({ components: {
  PlaceData, PlaceContactData, PlaceNotes, Icon, PlaceOrder, PlaceVisits
}})
export default class PlaceForm extends Mixins(PlacesMixin) {

  static readonly updatePlaceTag = 'update_place';
  private placeDataId = 'place_data';
  private placeContactDataId = 'place_contact_data';
  private placeNoteId = 'place_notes';
  private placeOrderId = 'place_order';
  private placeVisitsId = 'place_visits';

  private selectedItem = null;
  private sections = [
    {title: 'Dati contatto', name: 'place_data', completed: false, error: false},
    {title: 'Dati anagrafici', name: 'place_contact_data', completed: false, error: false},
    {title: 'Ordine', name: 'place_order', completed: false, error: false},
    {title: 'Visite', name: 'place_visits', completed: false, error: false},
    {title: 'Note', name: 'place_notes', completed: false, error: false}
  ];

  @Getter currentUser: User;
  @Getter currentPlace;

  private item = null;
  @RegisterHttp(PlaceForm.updatePlaceTag) http: RequestData;

  get candidatePlaceTag() {
    return `${PlaceEdit.candidatePlaceTag}_${this.place.id}`;
  }

  get downloadContractPreviewTag() {
    return `${PlaceEdit.downloadContractPreviewTag}_${this.place.id}`;
  }

  @Prop({required: false})
  place: Place;

  @Prop()
  disabled: boolean;

  @Getter('status', { namespace: 'http' }) status: { [key: string]: RequestData };

  @Watch('status',  { immediate: true, deep: true })
  onStatusUpdated(val) {
    if (!this.place) return;
    const errorObjects: Array<IValidationError> = [val[this.downloadContractPreviewTag], val[this.candidatePlaceTag]];
    errorObjects.forEach((element) => {
      if (element && element.statusCode === 422) {
        this.checkSectionError(element.errors);
      }
    });
  }

  @Watch('place')
  onPlaceUpdated(val, oldVal) {
    this.item = val;
    this.checkSectionCompletion();
  }

  checkSectionCompletion() {
    this.setCompleted('place_order', this.placeOrderCompleted);
    if (!this.currentUser.isExecutive || (this.currentUser.isExecutive && this.place.isOneOf(['scheduled', 'completed']) ))  this.setCompleted('place_data', this.placeDataCompleted);
    if (!this.currentUser.isTechnician)  this.setCompleted('place_contact_data', this.placeContactDataCompleted);
    if (!this.currentUser.isTechnician) this.setCompleted('place_notes', this.item.notes);
  }

  checkSectionError(errors) {
    this.setError('place_order', this.placeOrderError(errors.details));
    if (!this.currentUser.isExecutive) this.setError('place_data', this.placeDataError(errors.details));
    if (!this.currentUser.isTechnician)  this.setError('place_contact_data', this.placeContactDataError(errors.details));
    if (!this.currentUser.isTechnician) this.setError('place_notes', this.placeNotesError(errors.details));
  }

  get placeDataCompleted() {
    let place = this.currentPlace;
    const completed: boolean = place.name && place.placeTypeId && place.placeRecommendationTypeId
    && place.openingStatus && (place.competitorId || place.isNewBusiness) && place.placeOwnershipTypeId
    && place.contact.firstName && place.contact.lastName && place.contact.phone && place.contact.role && place.address?.fullAddress && place.isInTarget;
    return completed;
  }

  get placeOrderCompleted() {
    let order = this.currentPlace?.order;

    let valid = order.blendId && order.kilogramsPerMonth && order.price && order.paymentMethodId && order.installationDate && order.contractDurationNumber && order.contractDurationTypeName && order.newContractType;
    if (this.currentUser.isTechnician && this.place.isCustomer) {
      valid = valid && (!order.orderGears.length || order.orderGears.every(o => o.serialNumber));
    }

    return valid;
  }

  placeOrderError(errors) {
    const errorFields = ['order.kilograms_per_month', 'order.payment_method_id', 'order.credit_days', 'order.blend_id', 'order.installation_date', 'order.contract_duration_number','order.contract_duration_type_name', 'order.new_contract_type'];
    return Object.keys(errors).some(o => errorFields.includes(o));
  }

  placeDataError(errors) {
    const errorFields = ['name', 'place_type_id', 'place_recommendation_type_id', 'opening_status', 'competitor_id', 'place_ownership_id', 'contact.first_name', 'contact.last_name', 'contact.phone', 'contact.role', 'address.full_address', 'address.address', 'address.street_number', 'address.city_id'];
    return Object.keys(errors).some(o => errorFields.includes(o));
  }

  placeNotesError(errors) {
    const errorFields = ['notes'];
    return Object.keys(errors).some(o => errorFields.includes(o));
  }

  get placeContactDataCompleted() {
    let company = this.item.company;
    let contact = company.contact;
    const companyCompleted = company.businessName && company.address?.fullAddress && company.email && company.pec && company.sdi
    && company.phone && company.mobilePhone && (company.vatCode || company.fiscalCode);
    const companyContactCompleted = contact.firstName && contact.lastName && contact.address.fullAddress
    && contact.birthDate && contact.birthPlace && contact.fiscalCode && contact.phone;
    return companyCompleted && companyContactCompleted;
  }

  placeContactDataError(errors) {
    const errorFields = ['contact.first_name', 'contact.last_name', 'contact.address.full_address', 'company.contact.address.street_number', 'company.contact.address.address',
    'contact.birth_date', 'contact.birth_place', 'contact.fiscal_code', 'contact.phone', 'company.business_name', 'company.address.full_address', 'company.address.street_number', 'company.email', 'company.pec', 'company.sdi', 'company.phone', 'company.mobile_phone', 'company.vat_code', 'company.fiscal_code'  ];
    return Object.keys(errors).some(o => errorFields.includes(o));
  }

  setCompleted(name, completed) {
    let section = this.sections.find(o => o.name === name);
    section.completed = completed;
  }

  setError(name, error) {
    let section = this.sections.find(o => o.name === name);
    section.error = error;
  }

  setSelectedItem(section) {
    if (!section.disabled) this.selectedItem = section;
  }

  mounted() {
    if (this.currentUser.isTechnician) {
      this.sections = this.sections.filter(o => o.name !== 'place_notes' && o.name !== 'place_visits' && o.name !== 'place_contact_data');
    }
    if (this.currentUser.isExecutive && !this.place.isOneOf(['scheduled', 'completed'])) {
      this.sections = this.sections.filter(o => o.name !== 'place_visits' && o.name !== 'place_data');
    }
    this.selectedItem = this.sections[0];
    this.item = this.place;
  }

  setSection(id) {
    this.$router.push({query: { section: id }});
  }

  onSectionUpdated(data) {
    this.$emit('change', data);
  }

  isSelected(name: string) {
    return this.selectedItem.name === name;
  }
}
