import Model from './Model';
import IPlaceParameter from './interfaces/IPlaceParameter';
export default class PlaceParameter extends Model implements IPlaceParameter {

}

export const values = [
  { label: 'Provenienza', icon: 'FeatherArrowLeftCircle', roles: [], name: 'placeRecommendationTypes' },
  { label: 'Tipologia attività', icon: 'Shop', roles: [], name: 'placeTypes' },
  { label: 'Proprietario', icon: 'Key', roles: [], name: 'placeOwnershipTypes' },
  { label: 'Fornitori Concorrenti', icon: 'Group', roles: [], name: 'competitors' },
  { label: 'Miscele', icon: 'CoffeeBean', roles: [], name: 'blends' },
  { label: 'Metodo di pagamento', icon: 'CreditCard', roles: [], name: 'paymentMethods' },
  { label: 'Attrezzatura', icon: 'CoffeeMachine', roles: [], name: 'gears' },
  { label: 'Accessori', icon: 'Coffee', roles: [], name: 'accessories' },
  { label: 'Durata contratto', icon: 'Calendar', roles: [], name: 'contractDurationOptions' },
  { label: 'Condizioni Speciali', icon: 'File', roles: [], name: 'specialConditions' }
];
