import PlaceParameter from './PlaceParameter';
import Model from './Model';
import IActivityRecipient from './interfaces/IActivityRecipient';
import Activity from './Activity';

export default class ActivityRecipient extends Model implements IActivityRecipient {

  static type = 'activityRecipients';

  sent: boolean;
  read: boolean;
  activity: Activity;
}
