



























import { Component, Vue, Prop } from 'vue-property-decorator';
import ClickOutside from 'directives/ClickOutside';

@Component({
  directives: {ClickOutside},
})
export default class Modal extends Vue {

  visible: boolean = false;

  private hideable = false;

  @Prop({default: '', required: false})
  contentClasses?: string;

  @Prop({default: '', required: false})
  headerClasses?: string;

  @Prop({default: '', required: false})
  bodyClasses?: string;

  @Prop({default: '', required: false})
  footerClasses?: string;

  @Prop({default: true, required: false})
  hidesOnClickOut?: boolean;

  @Prop({default: 'md', required: false})
  size?: string;

  eventListener(e) {
    if (e.keyCode === 27) {
           this.hide();
    }
  }

  show() {
    this.visible = true;
    this.$emit('shown');
    setTimeout(() => this.hideable = true);
    this.registerKeyDownEvents();
  }

  registerKeyDownEvents() {
     window.addEventListener('keydown', this.eventListener);
  }

  unregisterKeyDownEvents() {
     window.removeEventListener('keydown', this.eventListener);
  }

  onOutsideClick() {
    if (this.hideable && this.hidesOnClickOut) {
      this.hide();
    }
  }

  hide() {
    this.visible = false;
    this.hideable = false;
    this.$emit('hidden');
    this.unregisterKeyDownEvents();
  }

  hasHeader() {
    return this.$slots.header != null;
  }

  hasFooter() {
    return this.$slots.footer != null;
  }

  onKeyPress($event: any) {
    if (this.visible) {
      this.hide();
    }
  }
}
