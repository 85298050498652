


























import {Vue, Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import OrdersMixin from 'mixins/http/OrdersMixin';
import GearSelect from 'components/Gear/Select.vue';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';

@Component({components: { InlineEditor, GearSelect }})
export default class CreateOrderGearItem extends Mixins(OrdersMixin) {

  static readonly createTag = 'create_order_gear';

  @RegisterHttp(CreateOrderGearItem.createTag) createRequestd: RequestData;

  @Prop({required: true})
  orderId: string;

  private data: any = {};
  private editing: boolean = false;

  get valid() {
    return this.data.gear_id;
  }

  reset() {
    this.data.model = undefined;
    this.data.gear_id = undefined;
  }

  onParameterChanged(value) {
    this.data = {
      order_id: this.orderId,
      ...this.data,
      ...value
    };
  }

  onSaveClicked() {

    if (!this.valid) {
      return;
    }

     this.createOrderGear(this.data, CreateOrderGearItem.createTag)
     .then(resp => {
        this.$emit('created', resp);
        this.reset();
        this.editing = false;
     });
  }

}
