import Vuex, { StoreOptions, Module, MutationTree, GetterTree } from 'vuex';
import RootState from './types/RootState';
import { ActionTree } from 'vuex';
import Place from 'models/Place';
import CurrentPlaceStore from './types/CurrentPlaceState';
import IPlace from 'models/interfaces/IPlace';
import { User } from 'models';

export const state: CurrentPlaceStore = {
  currentPlace: undefined,
};

export const getters: GetterTree<CurrentPlaceStore, RootState> = {
  currentPlace(state): Place | undefined {
    return state.currentPlace;
  }
};

export const mutations: MutationTree<CurrentPlaceStore> = {
  setCurrentPlace(state, payload: Place | undefined) {
    state.currentPlace = payload;
  },

  updateCurrentPlace(state, payload: IPlace) {
    state.currentPlace.status = payload.status;
  },

  addSharee(state, sharee: User) {
    state.currentPlace?.sharees.push(sharee);
  },

  removeSharee(state, sharee: User) {
    state.currentPlace.sharees = state.currentPlace?.sharees.filter(o => o.id !== sharee.id);
  }
};

export const actions: ActionTree<CurrentPlaceStore, RootState> = {
  setCurrentPlace({ commit }, place: Place) {
    commit('setCurrentPlace', place);
  },

  updateCurrentPlace({ commit }, mutation: IPlace) {

    commit('updateCurrentPlace', mutation);
  },

  addSharee({commit}, sharee: User) {
    commit('addSharee', sharee);
  },
  removeSharee({commit}, sharee: User) {
    commit('removeSharee', sharee);
  }
};

export const currentPlace: Module<CurrentPlaceStore, RootState> = {
  state,
  getters,
  mutations,
  actions
};
