














import { Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import IOrderGear from 'models/interfaces/IOrderGear';
import OrderMixins from 'mixins/http/OrdersMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import { Getter } from 'vuex-class';
import Icon from 'components/Icon.vue';
import { User, Place } from 'models';
import { showToast } from '../../utils/Toast';
import PlaceAuthorizationMixin from 'mixins/PlaceAuthorizationMixin';

@Component({components: { InlineEditor, Icon }})
export default class OrderGearItem extends Mixins(OrderMixins, PlaceAuthorizationMixin) {

  static readonly deleteOrderGearTag = 'delete_order_gear';
  static readonly updateOrderGearTag = 'update_order_gear';
  static readonly updateOrderGearTechnicianDataTag = 'update_order_gear_serial_number';

  @Prop({required: true})
  item: IOrderGear;

  @Getter currentUser?: User;
  @Getter currentPlace?: Place;

  @Prop({default: false}) disabled?: boolean;

  @RegisterHttp(OrderGearItem.deleteOrderGearTag) deleteRequest: RequestData;
  @RegisterHttp(OrderGearItem.updateOrderGearTag) updateRequest: RequestData;
  @RegisterHttp(OrderGearItem.updateOrderGearTechnicianDataTag) updateSerialNumberRequest: RequestData;

  private data = this.item;

  @Watch('item')
  onParameterValueChanged(val, oldVal) {
    this.data = val;
  }

  get canShowSerialNumber() {

    if (this.currentUser.isTechnician) {
      return this.currentPlace.isOneOf(['scheduled', 'completed', 'discarded']);
    }

    return this.currentPlace.isOneOf(['scheduled', 'completed', 'discarded', 'customer']) && !this.currentUser.isTechnician;
  }

  onParameterChanged(value) {
    let data = {
      id: this.data.id,
      ...value
    };

    this.updateOrderGear(data, OrderGearItem.updateOrderGearTag)
    .then(resp => {
      this.data = resp;
    }, err => {

    });
  }

  get canEditSerialNumber() {
    return this.canChangeSerialNumber(this.currentUser, this.currentPlace);
  }

  get canEditModel() {
    return this.canChangeModel(this.currentUser, this.currentPlace);
  }

  onTechnicianDataChanged(value) {
    let data = {
      id: this.data.id,
      ...value
    };

    this.updateOrderGearTechnicianData(data, OrderGearItem.updateOrderGearTechnicianDataTag)
    .then(resp => {
      this.data = resp;
      this.$emit('updated', resp);
      showToast('Dati modificati con successo!');
    }, err => {

    });
  }

  onDeleteClicked() {

     this.destroyOrderGear(this.data.id, OrderGearItem.deleteOrderGearTag)
     .then(resp => {
        this.$emit('deleted', resp);
     });
  }

}
