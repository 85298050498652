







import { Prop, Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Checkbox extends Vue {
  @Prop() value!: boolean | any[];
  @Prop({ default: null }) label: string | null;
  @Prop() inputValue: any;
  @Prop({ default: false }) disabled: boolean;

  private selected = this.value;

  get id() {
    return 'checkbox-' + this.randomValue();
  }

  get checkboxValue() {
    return this.inputValue ? this.inputValue : this.label;
  }

  @Watch('value')
  onValueChanged(value) {
    this.selected = value;
  }

  randomValue() {
    return Math.random().toString(36).substr(2, 9);
  }

  onChange(value) {
    this.$emit('input', this.selected);
  }
}
