import PlaceParameter from './PlaceParameter';

export default class PaymentMethod extends PlaceParameter {

  static type = 'paymentMethods';
  static parameterType = 'payment_methods';
  static parameterSingularType = 'payment_method';

  name?: string;
}
