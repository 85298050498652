
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PlaceParametersMixin extends Vue {

  getPlaceParameters(parameter_class: any, data: any, tag) {
    return this.$httpGet( this.$urlFor(`api_v1_${parameter_class.parameterType}`, { format: 'json', ...data }), tag);
  }

  createPlaceParameter(parameter_class: any, data: any, tag) {
    return this.$httpPost( this.$urlFor(`api_v1_${parameter_class.parameterType}`, { format: 'json' }), tag, data);
  }

  updatePlaceParameters(parameter_class: any, data: any, tag) {
    return this.$httpPut( this.$urlFor(`api_v1_${parameter_class.parameterSingularType}`, { format: 'json', id: data.id }), tag, data);
  }

  destroyPlaceParameter(parameter_class: any, id, tag) {
    return this.$httpDelete(this.$urlFor(`api_v1_${parameter_class.parameterSingularType}`, id ), tag);
  }
}
