






import { RequestData } from 'store/types/RequestStore';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class DisplayErrors extends Vue {
  @Prop() tag!: string;

  @Getter('status', { namespace: 'http' }) status: { [key: string]: RequestData };

  get errorMessage() {
    return this.status[this.tag]?.errors?.message;
  }

  get errors() {
    return this.status[this.tag]?.statusCode === 422 && this.status[this.tag]?.errors;
  }

  @Watch('errors', { immediate: true })
  onErrorsChanged(newValue: boolean) {
    if (newValue) {
      this.scrollToError();
    }
  }

  scrollToError() {
    this.$nextTick(() => {
      const errorContainer = this.$refs.errorContainer as HTMLElement;
      scrollTo({
        top: errorContainer.offsetTop - 100,
        behavior: 'smooth'
      }
      );
    });
  }
}
