









import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import Icon from 'components/Icon.vue';

@Component({ components: { InlineEditor, Icon } })
export default class NumberInput extends Vue {
  @Prop()
  value!: number;

  @Prop()
  minValue?: number;

  @Prop()
  maxValue?: number;

  @Prop()
  dataKeys?: string[];

  number: number = this.value;

  @Watch('number')
  onDataChanged(value) {
    if (this.minValue && value < this.minValue) {
      this.number = this.minValue;
    }
    if (this.maxValue && value > this.maxValue) {
      this.number = this.maxValue;
    }
    this.changed();
  }

  changed() {
    this.$emit('change', this.outputParams);
  }

  get outputParams() {
    let payload = {};
    if (this.dataKeys && this.dataKeys.length) {
      for (let i = 0; i < this.dataKeys.length; i++) {
        const key = this.dataKeys[i];
        const isLast = i === this.dataKeys.length - 1;

        if (i === 0) {
          payload[key] = isLast ? this.number : {};
        } else {
          let temp = null;
          for (let y = 0; y < i; y++) {
            temp = temp ? temp[this.dataKeys[y]] : payload[this.dataKeys[y]];
          }
          temp[key] = isLast ? this.number : {};
        }
      }
    } else {
      payload = this.number;
    }

    return payload;
  }

  increase() {
    if (this.maxValue) {
      if (this.number < this.maxValue) {
        this.number++;
      }
    } else {
      this.number++;
    }
  }

  decrease() {
    if (this.minValue) {
      if (this.number > this.minValue) {
        this.number--;
      }
    } else {
      this.number--;
    }
  }
}
