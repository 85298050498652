


































import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';

const moment = require('moment');

@Component({ components: { Modal } })
export default class DatePickerModal extends Vue {
  @Prop()
  title?: string;

  @Prop()
  dateLabel?: string;

  @Prop()
  timeLabel?: string;

  @Prop()
  datetime?: string;

  @Prop()
  minDate?: string;

  @Prop()
  maxDate?: string;

  private value = moment(this.datetime || new Date()).format('YYYY-MM-DD HH:mm');
  private payload = null;

  private open: boolean = false;

  show() {
    const modal = this.$refs.modal as any;
    modal.show();
  }

  get date() {
    if (this.value) return moment(this.value).format('YYYY-MM-DD');
  }

  set date(date) {
    if (date) {
      this.value = moment(date + ' ' + (this.time || '00:00'));
    }
  }

  get time() {
    if (this.value) {
       return moment(this.value).format('HH:mm');
    }
  }

  set time(time) {

    if (time) {
      this.value = moment(this.date || new Date());
      const timeMoment = moment(time, 'HH:mm');
      this.value.set({
        hour: timeMoment.get('hour'),
        minutes: timeMoment.get('minutes')
      });
    }
  }

  hide() {
    const modal = this.$refs.modal as any;
    modal.hide();
  }

  reset() {
    return (this.value = this.datetime);
  }

  isOpen() {
    return this.isOpen;
  }

  get dateConfig() {
    let config = {} as any;
    if (this.minDate) config.to = new Date(this.minDate);
    if (this.maxDate) config.from = new Date(this.maxDate);
    return config;
  }

  onCanceled() {
    this.hide();
    this.$emit('canceled');
    this.reset();
  }

  onConfirmed() {
    this.hide();
    this.$emit('confirmed', this.value);
  }
}
