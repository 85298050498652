








































































import BaseMultiSelect from './BaseMultiSelect.vue';
import Checkbox from './Checkbox.vue';
import ClickOutside from 'directives/ClickOutside';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface IMultiSelectOption {
  id?: string;
  name: string;
  group?: string;
}


@Component({ components: { BaseMultiSelect, Checkbox } , directives: {ClickOutside}})
export default class MultiSelect extends Vue {
  @Prop({ default: () => [] }) value: any[] | null;
  @Prop() options!: IMultiSelectOption[];
  @Prop() actions: any[] | null;
  @Prop() groups: any[] | null;
  @Prop() groupBy: (options: IMultiSelectOption[], group: string) => IMultiSelectOption[] | null;
  @Prop({ default: 'Seleziona un valore' }) placeholder: string;
  @Prop({ default: 'name' }) label: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) confirmSelection: boolean;
  @Prop({ default: false }) showDropdown: boolean;
  @Prop({ default: false }) searchEnabled: boolean;

  private selected = this.value;
  private maxElements = 50;
  private offset = 0;
  private search = null;
  private dropdownVisible = false;

  @Watch('value')
  onValueChanged(value) {
    this.selected = value;
  }

  @Watch('disabled')
  onDisabledChanged(value) {
    if (value) {
      this.selected = [];
    }
  }

  onOutsideClick() {
    this.dropdownVisible = false;
  }

  @Watch('selected')
  onSelectedChanged(value, oldValue) {
    if (!this.confirmSelection) {
      this.$emit('input', value);
    }
  }

  get isGrouped() {
    return this.groups && this.groups.length;
  }

  get groupedOptions() {
    return this.groups
      .map(group => {
        group.options = this.getGroupedOptions(group);
        return group;
      })
      .filter(group => {
        return group.options.length > 0;
      });
  }

  get filteredOptions() {
    if (this.search) {
      return this.options.filter(option => {
        return (
          option.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    } else {
      return this.options;
    }
  }

  getActionCssClass(action) {
    return {
      'list-inline-item': true,
      active: action.active
    };
  }

  getGroupedOptions(group) {
    if (this.groupBy instanceof Function) {
      return this.groupBy(this.options, group);
    } else {
      return this.options.filter(element => {
        let valid = element.group === group.id;

        if (this.search) {
          valid =
            valid &&
            element.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        }

        return valid;
      });
    }
  }

  onSelectAll() {
    this.selected = [];

    for (const option of this.filteredOptions) {
      this.selected.push(option);
    }

    if (this.actions) {
      this.actions.map(element => {
        return (element.active = true);
      });
    }
  }

  onSelectGroup(group) {
    const filtered = this.filteredOptions.filter(
      element => element.group === group.id
    );
    const selectedSize = this.selected.length;
    this.selected = [...new Set([...this.selected, ...filtered])];
    if (selectedSize === this.selected.length) {
      this.selected = this.selected.filter(x => !filtered.includes(x));
    }
  }

  onReset() {
    if (this.actions) {
      this.actions.map(element => {
        return (element.active = false);
      });
    }
  }

  onApply() {
    this.dropdownVisible = false;
    this.$emit('input', this.selected);
  }

  onCleared() {
    if (this.confirmSelection) {
      this.$emit('input', this.selected);
    }
  }

  onScroll(e, holder) {
    if (!holder) {
      return;
    }
    if (!holder.getBoundingClientRect) {
      return;
    }
    const upperSensors = this.$refs.upperSensor as Element[];
    const lowerSensors = this.$refs.lowerSensor as Element[];

    if (upperSensors) {
      const holderRect = holder.getBoundingClientRect();

      for (let i = 0; i < upperSensors.length; i++) {
        const sensor = upperSensors[i];
        const { top, bottom, height } = sensor.getBoundingClientRect();
        if (holderRect.top - top < 0) {
          this.offset = parseInt(sensor.getAttribute('data-index'));
          break;
        }
      }
    }

    if (lowerSensors) {
      const holderRect = holder.getBoundingClientRect();

      for (let i = lowerSensors.length - 1; i >= 0; i--) {
        const sensor = lowerSensors[i];
        const { top, bottom, height } = sensor.getBoundingClientRect();
        if (holderRect.bottom - top > 0) {
          this.offset = parseInt(sensor.getAttribute('data-index'));
          break;
        }
      }
    }
  }

  onAction(action) {
    if (action.filter && action.filter instanceof Function) {
      let filtered = this.filteredOptions.filter(action.filter);

      if (action.active) {
        this.selected = this.selected.filter(
          element => filtered.indexOf(element) === -1
        );
        action.active = false;
      } else {
        this.selected = [...new Set([...this.selected, ...filtered])];
        action.active = true;
      }
    }
  }

  onSearch(term) {
    this.search = term;
  }

  onToggleVisible(visible) {
    if (visible) {
      this.offset = 0;
    }

    this.dropdownVisible = visible;
  }
}
