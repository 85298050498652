import Vue from 'vue'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';

import VueMoment from 'vue-moment';
import * as moment from 'moment';
import 'moment/locale/it';

moment.updateLocale('it', { week: { dow: 1, doy: 4 }});

// Datepicker requires the first day to be monday
const weekdaysShort = [...moment.weekdaysShort(true)];
const weekdays = [...moment.weekdays(true)];
const months = [...moment.months()];

// Ad Control convention is to use sunday first
moment.updateLocale('it', { week: { dow: 0, doy: 4 }});

Vue.use(VueMoment, {
  moment
});

const datepickerOptions = {
  sundayFirst: true,
  days: weekdays,
  daysShort: weekdaysShort,
  monthNames: months,
  texts: {
    cancel: 'Chiudi',
    apply: 'Seleziona',
    keyboardShortcuts: 'Comandi da tastiera',
  },
  keyboardShortcuts: [
    {symbol: '↵', label: 'Seleziona la data attiva', symbolDescription: 'Invio'},
    {symbol: '←/→', label: 'Muovi avanti (destra) o indietro (sinistra) di un giorno.', symbolDescription: 'Frecce sinistra/destra'},
    {symbol: '↑/↓', label: 'Muovi avanti (sopra) o indietro (sotto) di una settimana.', symbolDescription: 'Frecce su/giù'},
    {symbol: 'PgUp/PgDn', label: 'Cambia mese.', symbolDescription: 'PagSu'},
    {symbol: 'Home/End', label: 'Vai al primo giorno del mese', symbolDescription: 'Tasto Home o End'},
    {symbol: 'Esc', label: 'Chiudi', symbolDescription: 'Esc'},
    {symbol: '?', label: 'Apri', symbolDescription: 'Punto di domanda'}
  ],
};

Vue.use(AirbnbStyleDatepicker, datepickerOptions);
