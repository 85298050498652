




import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { RequestData } from 'store/types/RequestStore';

@Component
export default class DisplayErrorsInline extends Vue {
  @Prop() tag!: string | RegExp | string[] | RegExp[];
  @Prop() keys!: Array<string | RegExp>;
  @Getter('status', { namespace: 'http' }) status: { [key: string]: RequestData };

  get tags(): string[] {
    const tag = this.tag;
    if (tag instanceof RegExp) {
      return Object.keys(this.status).filter(e => tag.test(e));
    } else if (Array.isArray(tag)) {
      if(tag.every(item => item instanceof RegExp)){
        return Object.keys(this.status).filter(e => tag.some(regexp => regexp.test(e)));
      }
      return Object.keys(this.status).filter(e => tag.some(str => e === str));
    } else {
      return [tag];
    }
  }

  get errors() {
    return this.tags.reduce((result, tag) => {

      if (!this.status[tag]) {
        return result;
      }
      if (this.status[tag].statusCode !== 422) {
        return result;
      }

      for (const key in this.status[tag].errors?.details) {
        const match = this.keys.some(e => {
          return (e instanceof RegExp) ? e.test(key) : e === key;
        });
        if (match) {
          return true;
        }
      }
      return result;

    }, false);
  }
}
