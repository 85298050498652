import PlaceParameter from './PlaceParameter';

export enum MeasurementUnit {
  Kg = 'kg',
  Piece = 'piece'
}

export const MEASUREMENT_UNIT_TRANSLATIONS: Record<MeasurementUnit, string> = {
  kg: 'Kg',
  piece: 'Pezzi'
}

export default class Blend extends PlaceParameter {

  static type = 'blends';
  static parameterType = 'blends';
  static parameterSingularType = 'blend';

  name?: string;
  price?: number;
  measurementUnit!: MeasurementUnit;
}
