























import { Vue, Component, Prop } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import { Place, User } from 'models';
import { Getter } from 'vuex-class';

@Component({ components: { InlineEditor } })
export default class PlaceData extends Vue {
  @Prop({ required: true })
  place: Place;

  @Getter currentUser?: User;

  @Prop({default: false}) disabled?: boolean;

  onDataUpdated(data) {
    this.$emit('updated', data);
  }

  get canSeeAcceptNotes() {
    return this.currentUser.isAdmin
           || this.currentUser.isManager
           || this.currentUser.isExecutive
           || (this.currentUser.isAgent && this.currentUser == this.place.agent)
  }
}
