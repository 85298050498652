


















































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { Place } from 'models';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import ModelSelect from 'components/ModelSelect.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
import PlacesAutocomplete from 'components/Google/PlacesAutocomplete.vue';
import Address from '../../models/Address';

@Component({ components: { Modal, ModelSelect, Loader, PlacesAutocomplete } })
export default class PlaceCreate extends Mixins(PlacesMixin) {
  @Prop({
    default: () => {
      return {};
    }
  })
  resource?: Place;

  private place?: Place = this.resource;
  private static httpTag = 'create_place';

  @RegisterHttp(PlaceCreate.httpTag) http: RequestData;

  get valid() {
    return this.place && this.place.name && this.place.google_maps_location;
  }

  title(): string {
    return 'Aggiungi contatto';
  }

  show() {
    const modal = this.$refs.modal as any;
    modal.show();
  }

  hide() {
    const modal = this.$refs.modal as any;
    modal.hide();
    this.reset();
  }

  reset() {
    this.place = new Place({});
  }

  onPlaceSelected(data: { address_components: any[], formatted_address: string, geometry: { location: any } }) {
    this.place.address = new Address({ attributes: { fullAddress: data.formatted_address } });
    this.$set(this.place, 'google_maps_location', data.address_components);
    this.place.latitude = data?.geometry?.location?.lat();
    this.place.longitude = data?.geometry?.location?.lng();
  }

  save() {
    const request = this.createPlace(this.place, PlaceCreate.httpTag);
    request.then(
      resp => {
        this.onSaved(resp);
      }
    );
  }

  onSaved(place: Place) {
    this.hide();
    this.$emit('created', place);
  }

  onCancel() {
    this.hide();
    this.$emit('canceled');
  }
}
