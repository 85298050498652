import PlaceParameter from './PlaceParameter';

export default class ContractType extends PlaceParameter {

  static type = 'contractTypes';
  static parameterType = 'contract_types';
  static parameterSingularType = 'contract_type';

  name?: string;
}
