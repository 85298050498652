









































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import { Place } from 'models';
import PlaceTypeSelect from 'components/PlaceType/Select.vue';
import CustomSelect from 'components/Place/CustomSelect.vue';
import PlaceRecommendationTypeSelect from 'components/PlaceRecommendationType/Select.vue';
import PlaceOwnershipTypeSelect from 'components/PlaceOwnershipType/Select.vue';
import CompetitorSelect from 'components/Competitor/Select.vue';
import InlinePlacesAutocomplete from 'components/Google/InlinePlacesAutocomplete.vue';
import InlineMultiSelect from 'components/InlineMultiSelect.vue';

@Component({
  components: {
    InlineMultiSelect,
    InlinePlacesAutocomplete,
    InlineEditor,
    PlaceTypeSelect,
    CustomSelect,
    PlaceRecommendationTypeSelect,
    PlaceOwnershipTypeSelect,
    CompetitorSelect
  }
})
export default class PlaceData extends Vue {
  @Prop({ required: true })
  place: Place;

  @Prop({ default: true })
  disabled: boolean;

  weekDays = [
    { id: 'monday', name: 'Lunedì' },
    { id: 'tuesday', name: 'Martedì' },
    { id: 'wednesday', name: 'Mercoledì' },
    { id: 'thursday', name: 'Giovedì' },
    { id: 'friday', name: 'Venerdì' },
    { id: 'saturday', name: 'Sabato' },
    { id: 'sunday', name: 'Domenica' },
    { id: 'none', name: 'Nessuno' }
  ];

  openingStatuses: Array<{ name: string; id: string }> = [
    { name: "Nuova apertura", id: "new_business" },
    { name: "Attività esistente", id: "existing_business" },
  ];
  
  booleanOptions: Array<{ name: string; id: number }>  = [
    { name: "Sì", id: 1 },
    { name: "No", id: 0 },
  ]; 

  onDataUpdated(data) {
    this.$emit('updated', data);
  }

  onClosingDaysUpdated(newData) {
    if(newData.closingDays && newData.closingDays.length > 1 && newData.closingDays.includes('none')){
      if(this.place.closingDays.includes('none')){
        this.place.closingDays = newData.closingDays.filter(el => el != 'none');
      }else{
        this.place.closingDays = ['none'];
      }
    }

    this.$emit('updated', newData);
  }

  onRadioChanged(value) {
    if (this.disabled) return;
    this.place.newCustomer = value;
    this.$emit('updated', { newCustomer: value ? '1' : '0' });
  }

  onStreetNumberPresenceChanged() {
    this.$emit('updated', {
      address: {
        missingStreetNumber: !this.place.address.missingStreetNumber ? '1' : '0'
      }
    });
  }
}
