




















































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import VisitsMixin from 'mixins/http/VisitsMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import ModelSelect from 'components/ModelSelect.vue';
import PlaceSelect from 'components/Place/Select.vue';
import TypologySelect from 'components/Visit/TypologySelect.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
const moment = require('moment');

@Component({
  components: { Modal, ModelSelect, Loader, PlaceSelect, TypologySelect }
})
export default class VisitCreate extends Mixins(VisitsMixin) {

  @Getter
  currentUser;

  @Prop()
  placeId: number;
  @Prop()
  typology: string;

  @Prop({ default: false })
  lockPlaceSelection?: boolean;

  data: any = {
    place_id: this.placeId,
    date: new Date().toISOString().substr(0, 10),
    typology: this.typology
  }

  private httpTag = 'edit_visit_modal';

  @RegisterHttp('edit_visit_modal') http: RequestData;

  mounted() {
    this.onPlaceSelected(this.placeId);
    this.onTypologySelected(this.typology);
  }

  get valid() {
    return this.data.date && this.data.place_id && this.data.text && this.data.typology;
  }

  title(): string {
    return 'Registra visita';
  }

  show() {
    const modal = this.$refs.modal as any;
    modal.show();
  }

  onPlaceSelected(value) {
    this.$set(this.data, 'place_id', value);
  }

  onTypologySelected(value) {
    this.$set(this.data, 'typology', value);
  }

  hide() {
    const modal = this.$refs.modal as any;
    this.reset();
    modal.hide();
  }

  onDateChanged(data) {
    this.$set(this.data, 'date', moment(data).format('YYYY-MM-DD'));
  }

  reset() {
    this.data = {};
    if (this.placeId) this.data.place_id = this.placeId;
  }

  get createParams() {
    let data = {
      ...this.data,
      author_id: this.currentUser.id
    };
    return data;
  }

  save() {
    this.createVisit(this.createParams, this.httpTag)
      .then(
        resp => {
          this.onSaved(resp);
        },
        err => { }
      );
  }

  onSaved(resp) {
    this.hide();
    this.$emit('created', resp);
  }

  onCancel() {
    this.hide();
    this.$emit('canceled');
  }
}
