import Vuex, { StoreOptions, Module, MutationTree, GetterTree } from 'vuex';
import RootState from './types/RootState';
import { ActionTree } from 'vuex';
import User from 'models/User';
import CurrentUserStore from './types/CurrentUserState';
import axios from 'axios';
import Parser from 'models/Parser';
const Routes = require('../utils/RailsRoutes');

export const state: CurrentUserStore = {
  currentUser: undefined,
};

export const getters: GetterTree<CurrentUserStore, RootState> = {
  currentUser(state): User | undefined {
    return state.currentUser;
  }
};

export const mutations: MutationTree<CurrentUserStore> = {
  setCurrentUser(state, payload: User | undefined) {
    state.currentUser = payload;
  },
};

export const actions: ActionTree<CurrentUserStore, RootState> = {
  loadCurrentUser({ commit }, options: any = {}) {
    return axios({ url: Routes.api_v1_current_user_path(), ...options })
      .then(
        (response) => {
          const user: User = Parser.parse(response?.data);
          if (user) {
            commit('setCurrentUser', user);
            return Promise.resolve(user);
          } else {
            commit('setCurrentUser', undefined);
            return Promise.reject(undefined);
          }
        },
        (error) => {
          commit('setCurrentUser', undefined);
          return Promise.reject(undefined);
        }
      );
  }
};

export const currentUser: Module<CurrentUserStore, RootState> = {
  state,
  getters,
  mutations,
  actions
};
