
import { Component, Mixins } from 'vue-property-decorator';
import RequestsMixin from 'mixins/http/RequestsMixin';
import Axios from 'axios';
import moment from 'moment';

@Component
export default class PlacesMixin extends Mixins(RequestsMixin) {

  getPlaces(data: any, tag) {
    return this.$httpGet( this.$urlFor('api_v1_places', { format: 'json', ...data }), tag);
  }

  dumpPlaces(data: any, tag) {
    return Axios({
      method: 'get',
      url: this.$urlFor('dump_api_v1_places', { format: 'xlsx', ...data }),
      responseType: 'arraybuffer',
    }).then((response) => {
      let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Contatti-${moment().format('DD-MM-YYYY')}.xlsx`;
      link.click();
    });
  }

  getPlace(id, tag) {
    return this.$httpGet( this.$urlFor('api_v1_place', { format: 'json', id: id}), tag);
  }

  destroyPlace(id, tag) {
    return this.$httpDelete(this.$urlFor('api_v1_place', id ), tag);
  }

  createPlace(data: any, tag) {
    return this.$httpPost(this.$urlFor('api_v1_places'), tag, data);
  }

  updatePlace(data: any, tag) {
     return this.$httpPut(this.$urlFor('api_v1_place', { id: data.id }), tag, data);
  }

  assign(data: any, tag) {
    return this.$httpPut(this.$urlFor('assign_api_v1_place', { id: data.id }), tag, data);
  }

  reject(data: any, tag) {
    return this.$httpPut(this.$urlFor('reject_api_v1_place', { id: data.id }), tag, data);
  }

  candidate(data: any, tag) {
    return this.$httpPut(this.$urlFor('candidate_api_v1_place', { id: data.id }), tag, data);
  }

  recandidate(data: any, tag) {
    return this.$httpPut(this.$urlFor('recandidate_api_v1_place', { id: data.id }), tag, data);
  }

  accept(data: any, tag) {
    return this.$httpPut(this.$urlFor('accept_api_v1_place', { id: data.id }), tag, data);
  }

  reassign(data: any, tag) {
    return this.$httpPut(this.$urlFor('reassign_api_v1_place', { id: data.id }), tag, data);
  }

  complete(data: any, tag) {
    return this.$httpPut(this.$urlFor('complete_api_v1_place', { id: data.id }), tag, data);
  }

  discard(data: any, tag) {
    return this.$httpPut(this.$urlFor('discard_api_v1_place', { id: data.id }), tag, data);
  }

  schedule(data: any, tag) {
    return this.$httpPut(this.$urlFor('schedule_api_v1_place', { id: data.id }), tag, data);
  }

  notifyPlace(data: any, tag) {
    return this.$httpPut(this.$urlFor('notify_api_v1_place', { id: data.id }), tag, data);
  }

  downloadContractPreview(id: string, tag) {
    return this.$httpGet(this.$urlFor('download_contract_preview_api_v1_place', {id: id}), tag, 'arraybuffer');
  }
}