import PlaceParameter from './PlaceParameter';

export default class SpecialCondition extends PlaceParameter {

  static type = 'specialConditions';
  static parameterType = 'special_conditions';
  static parameterSingularType = 'special_condition';

  name?: string;
  disabled?: boolean;
}
