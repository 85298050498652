import Vue from 'vue';
import Vuex from 'vuex';
import { http } from './http';
import { auth } from './auth';
import { currentUser } from './currentUser';
import { currentPlace } from './currentPlace';

Vue.use(Vuex);
import VuexPersist from 'vuex-persist';

const vuexPersist = new VuexPersist({
  key: 'trismoka-crm',
  storage: window.localStorage,
  modules: ['auth']
});

export default new Vuex.Store({
  modules: {
    auth, currentUser, http, currentPlace
  },
  plugins: [vuexPersist.plugin]
});
