















































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import VisitsMixin from 'mixins/http/VisitsMixin';
import { RegisterHttp } from 'utils/Decorators';
import {Getter} from 'vuex-class';
import { RequestData } from 'store/types/RequestStore';
import Create from 'components/Visit/Create.vue';
import Update from 'components/Visit/Update.vue';
import DataTable from '@monade/vue-components/src/components/DataTable.vue';
import { Visit, Place, User } from '../../models';
import Paginator from '@monade/vue-components/src/components/Paginator.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
import VisitRow from 'components/Visit/TableRow.vue';

@Component({
  components: { DataTable, Loader, VisitRow, Create, Update, Paginator }
})
export default class VisitList extends Mixins(VisitsMixin) {
  static readonly getVisitsTag = 'get_visits';

  readonly createModalName = 'createVisitModal';
  readonly updateModalName = 'updateVisitModal';

  @RegisterHttp(VisitList.getVisitsTag) getVisitsRequest: RequestData;

  @Prop()
  filter: any;

  @Prop()
  place: Place;

  @Getter currentUser?: User;

  selectedVisit?: Visit = null;

  visits: Visit[] = [];

  page: number = 1;

  private loading: boolean = false;

  onCreateConfirm(data) {
    this.showModal(this.createModalName, false);
    this.loadVisits();
  }

  onPageChange(page) {
    this.page = page;
    this.loadVisits();
  }

  onVisitClicked(visit) {

    if (this.currentUser.id !== visit.author.id) return;

    this.selectedVisit = visit;

    this.$nextTick(() => {
      this.showModal(this.updateModalName, true);
    });
  }

  get canRegister() {
    return !this.currentUser.isOneOf(['technician', 'executive', 'admin']);
  }

  showModal(name: string, show: boolean) {
    const modal = this.$refs[name] as any;
    show ? modal.show() : modal.hide();
  }

  onCreateCancel(data) {
    this.showModal(this.createModalName, false);
  }

  onUpdateConfirm(data) {
    this.selectedVisit = null;
    this.showModal(this.updateModalName, false);
    this.loadVisits();
  }

  onUpdateCancel(data) {
    this.selectedVisit = null;
    this.showModal(this.updateModalName, false);
  }

  mounted() {
    this.loadVisits();
  }

  get params() {
    let data = {
      filter: {
        ...this.filter
      },
      page: this.page
    };

    return data;
  }

  loadVisits() {
    this.loading = true;
    this.getVisits(this.params, VisitList.getVisitsTag).then(
      resp => {
        this.visits = resp;
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }
}
