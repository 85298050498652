import PlaceParameter from './PlaceParameter';

export default class PlaceOwnershipType extends PlaceParameter {

  static type = 'placeOwnershipTypes';
  static parameterType = 'place_ownership_types';
  static parameterSingularType = 'place_ownership_type';

  name?: string;
}
