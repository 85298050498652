















































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import UserSelect from 'components/User/Select.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
import PaymentMethodSelect from 'components/PaymentMethod/Select.vue';
import ContractTypeSelect from 'components/ContractType/Select.vue';
import { Place } from 'models';
import { showToast } from 'utils/Toast';

@Component({ components: { Modal, Loader, UserSelect, PaymentMethodSelect, ContractTypeSelect } })
export default class AcceptPlace extends Mixins(PlacesMixin) {
  static readonly acceptPlaceTag = 'accept_place';

  @Prop()
  readonly place!: Place;
  payload: any = {
    consigneeId: this.place.agent.id, creditDays: this.place.order.creditDays,
    paymentMethodId: (this.place.order as any).paymentMethodId,
    contractTypeId: (this.place.order as any).contractTypeId,
    version: (this.place as any).version,
    acceptNotes: ''
  };

  @RegisterHttp(AcceptPlace.acceptPlaceTag) acceptPlaceRequest: RequestData;

  get valid() {
    return (
      this.payload.creditDays &&
      this.payload.consigneeId &&
      ((this.payload.contractYears && this.place.version === 1) || this.place.version > 1) &&
      ((this.payload.contractTypeId && this.place.version === 1) || this.place.version > 1)
    );
  }

  get requestTag() {
    return AcceptPlace.acceptPlaceTag;
  }

  show() {
    const modal = this.$refs.modal as any;
    modal.show();
  }

  onDataUpdated(data) {
    this.payload = { ...this.payload, ...data };
  }

  hide() {
    const modal = this.$refs.modal as any;
    modal.hide();
  }

  reset() {
    this.payload = {};
  }

  acceptPlace() {
    let data = {
      id: this.place.id,
      ...this.payload
    };

    this.accept(data, AcceptPlace.acceptPlaceTag).then(resp => {

      this.onSaved();
      showToast('Contatto accettato con successo!');
    });
  }

  onSaved() {
    this.reset();
    this.hide();
    this.$emit('accepted');
  }

  onCancel() {
    this.payload.contractTypeId = null;
    this.payload.contractYears = null;
    this.hide();
    this.$emit('canceled');
  }
}
