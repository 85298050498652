






























import { Component, Prop, Mixins } from "vue-property-decorator";
import ModelSelect from "components/ModelSelect.vue";
import { RequestData } from "/store/types/RequestStore";
import { RegisterHttp } from "utils/Decorators";
import UsersMixin from "mixins/http/UsersMixin";
import { User } from "models";
import ObjectPathMixin from "mixins/ObjectPathMixin";
import stc from "string-to-color";

@Component({ components: { ModelSelect } })
export default class UserSelect extends Mixins(ObjectPathMixin, UsersMixin) {
  static readonly getUserstag = "get_users_tag";

  @Prop() idSelected?: string | number;
  @Prop() params: any;
  @Prop() formClass: string;
  @Prop() classes: string;
  @Prop() dataKeys?: Array<string>;
  @Prop() placeholder?: string;
  @Prop({ default: false }) actsLikeFilter?: boolean;
  @Prop({ required: false }) queryKey: string;
  @Prop({ default: false }) showColor: boolean;
  @Prop({ default: false }) onlyActive: boolean;

  @RegisterHttp(UserSelect.getUserstag) http: RequestData;

  private items: any = null;
  private rawItems: User[] = [];
  private id: any = this.idSelected;

  private filter = { ...this.params };

  get queryParams() {
    return { ...this.params };
  }

  get all() {
    return { id: "all", name: "Tutti" };
  }

  color(key: string) {
    const item = this.item(key);
    return item ? stc(item?.email) : "transparent";
  }

  item(id: string) {
    return this.rawItems.find((o: any) => o.id.toString() === id.toString());
  }

  mounted() {
    this.loadItems();
  }

  onSearch(obj) {
    this.filter.term = obj;
    this.loadItems();
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit("change", this.outputParams(data));
  }

  loadItems() {
    this.getUsers(
      { filter: { ...this.filter }, per: 1000 },
      UserSelect.getUserstag
    ).then(
      (resp) => {
        this.rawItems = resp;
        if(this.onlyActive){
          this.rawItems = this.rawItems.filter(u => u && u.active === true);
        }
        this.items = this.rawItems.map((o) => {
          return { id: o.id, name: o.fullname() };
        });
        if (this.actsLikeFilter) {
          this.items.unshift(this.all);
        }
        if (this.idSelected) {
          this.id = this.idSelected;
        } else {
          this.id =
            this.queryKey && this.$route.query[this.queryKey]
              ? this.$route.query[this.queryKey]
              : this.items[0].id;
        }
      },
      (err) => {}
    );
  }
}
