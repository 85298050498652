
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PlaceSharingsMixin extends Vue {

  sharePlace(data: any, tag) {
    return this.$httpPost( this.$urlFor('share_api_v1_place_sharings'), tag, data);
  }

  unsharePlace(data: any, tag) {
    return this.$httpPost( this.$urlFor('unshare_api_v1_place_sharings'), tag, data);
  }
}
