
import { Component, Mixins } from 'vue-property-decorator';
import RequestsMixin from 'mixins/http/RequestsMixin';

@Component
export default class ActivityRecipientsMixin extends Mixins(RequestsMixin) {

  getActivityRecipients(data: any, tag) {
    return this.$httpGet( this.$urlFor('api_v1_activity_recipients',  {...data}), tag);
  }

  updateActivityRecipient(data: any, tag) {
    return this.$httpPut( this.$urlFor('api_v1_activity_recipient', { id: data.id }), tag, data);
  }

  unreadAllActivityRecipients(data: any, tag) {
    return this.$httpPost( this.$urlFor('unread_all_api_v1_activity_recipients'), tag, data);
  }
}
