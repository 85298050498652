import {User} from 'models';
import Model from './Model';

export default class Client extends Model {
  static type = 'clients';

  name?: string;
  agent?: User;
  lat: number;
  lon: number;
}
