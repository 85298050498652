


















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import {PlaceType} from 'models';
import { RequestData } from '/store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import PlaceParametersMixin from 'mixins/http/PlaceParametersMixin';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class PlaceTypeSelect extends Mixins(PlaceParametersMixin, ObjectPathMixin) {
  static readonly getPlaceTypesTag = 'get_place_types';
  @RegisterHttp(PlaceTypeSelect.getPlaceTypesTag) http: RequestData;

  @Prop() idSelected?: string | number;
  @Prop() params;
  @Prop() dataKeys?: Array<string>;
  @Prop({default: false}) disabled?: boolean;


  private items: any = null;

  get queryParams() {
    return {per: 1000, ...this.params};
  }

  mounted() {
      this.loadItems();
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
  }

  loadItems() {
    this.getPlaceParameters(PlaceType, this.queryParams, PlaceTypeSelect.getPlaceTypesTag).then(
      resp => {
        this.items = resp.filter((element) => element.disabled === false);
      }
    );
  }
}
