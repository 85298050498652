









import { Vue, Component } from 'vue-property-decorator';
import AgentBottomBar from 'components/AgentBottomBar.vue';
import { Getter } from 'vuex-class';
import { User } from 'models';
@Component({ components: { AgentBottomBar } })
export default class Container extends Vue {
  @Getter currentUser?: User;

  get showBottomBar() {
    return this.currentUser && this.currentUser.isAgent;
  }
}
