













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MultiSelect, { IMultiSelectOption } from './MultiSelect.vue';

@Component({ components: { MultiSelect } })
export default class InlineMultiSelect extends Vue {
  @Prop({ default: () => [] }) value: any[] | null;
  @Prop({ default: 'Seleziona un valore' }) placeholder: string;
  @Prop({ default: 'name' }) label: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) showDropdown: boolean;
  @Prop({ default: false }) searchEnabled: boolean;
  @Prop() dataKeys?: string[];
  @Prop() options!: IMultiSelectOption[];

  private selected: any[] | null = null;
  private preventUpdate: boolean = false;

  created() {
    if (!this.value) {
      return;
    }
    this.selected = this.options.filter(e => this.value.includes(e) || this.value.find(s => s === e?.id));
  }

  @Watch('value')
  onValueChanged(value: any[], oldVal: any[]) {

    if (!value || value === oldVal) {
      // this.preventUpdate = true;
      return;
    }

    if (value.length === this.selected.length) {
      if (value.every((e, index) => e === this.selected[index]?.id)) {
        // this.preventUpdate = true;
        return;
      }
    }
    this.selected = this.options.filter(e => value.includes(e) || value.find(s => s === e?.id));
  }

  onChanged(value) {
    if (!this.disabled) {
      this.$emit('change', this.outputParams(value));
     }
  }

  outputParams(value) {
    let payload = {};
    if (this.dataKeys && this.dataKeys.length) {
      for (let i = 0; i < this.dataKeys.length; i++) {
        const key = this.dataKeys[i];
        const isLast = i === this.dataKeys.length - 1;

        if (i === 0) {
          payload[key] = isLast ? value.map(e => e.id) : {};
        } else {
          let temp = null;
          for (let y = 0; y < i; y++) {
            temp = temp ? temp[this.dataKeys[y]] : payload[this.dataKeys[y]];
          }
          temp[key] = isLast ? value.map(e => e.id) : {};
        }
      }
    } else {
      payload = value.map(e => e.id);
    }

    return payload;
  }
}
