/* eslint no-console: 0 */
import "core-js/modules/es7.promise.finally";
import Vue from "vue";
import App from "../App.vue";
import "../styles/app.scss";
import Toasted from "vue-toasted";
import VueRouter from "vue-router";
import RoutingMixin from "mixins/Routing";
import ModelParserMixin from "mixins/ModelParser";
import RouteHelpersMixin from "mixins/RouteHelpers";
import RequestsMixin from "mixins/http/RequestsMixin";
import DisplayErrors from "components/DisplayErrors.vue";
import DisplayErrorsInline from "components/DisplayErrorsInline.vue";
import ContentLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "../initializers";
import router from "router";
import VueLayers from "vuelayers";

import store from "store";

declare module "vue/types/vue" {
  interface Vue {
    $urlFor(name: string, ...args: any[]);
    $httpGet(url: string, tag: string);
    $httpPost(url: string, tag: string, data: any);
    $httpPut(url: string, tag: string, data: any);
    $httpDelete(url: string, tag: string);
  }
}

Vue.use(VueRouter);
Vue.use(Toasted);
Vue.use(VueLayers);
Vue.component("rotate-loader", require("vue-spinner/src/RotateLoader.vue"));
Vue.component("display-errors", DisplayErrors);
Vue.component("display-errors-inline", DisplayErrorsInline);
Vue.component("content-loading", ContentLoading);

Vue.mixin(RoutingMixin);
Vue.mixin(ModelParserMixin);
Vue.mixin(RouteHelpersMixin);
Vue.mixin(RequestsMixin);
document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount();
  document.body.appendChild(app.$el);
});
