











import {Vue, Component, Prop} from 'vue-property-decorator';
import Icon from 'components/Icon.vue';

@Component({components: {Icon}})
export default class SearchBar extends Vue {

  private query?: string = null;
  private timeout?: any = null;

  @Prop({required: false, default: 'Cerca...'})
  searchPlaceholder?: string;

  @Prop({required: false, default: 350})
  inputTimeout: number;

  @Prop({required: false, default: 3})
  minSearchLength: number;

  onSearch() {
    this.$emit('search',  this.query);
  }

  get isSearchable() {
    if (this.query == null) return false;
    return this.query.length >= this.minSearchLength;
  }

  emitSearch(term) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.$emit('search', term);
    }, this.inputTimeout);
  }

  onKeyUp(event) {
    if (this.isSearchable) {
      this.emitSearch(this.query);
    } else {
      this.emitSearch('');
    }
  }
}
