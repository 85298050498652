














































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
import { showToast } from 'utils/Toast';

@Component({ components: { Modal, Loader } })
export default class RejectPlace extends Mixins(PlacesMixin) {
  static readonly rejectPlaceTag = 'reject_place';
  private rejectionReason?: string = null;

  @Prop()
  placeId: string | number;

  @RegisterHttp(RejectPlace.rejectPlaceTag) rejectPlaceRequest: RequestData;

  get valid() {
    return this.rejectionReason;
  }

  get requestTag() {
    return RejectPlace.rejectPlaceTag;
  }

  show() {
    const modal = this.$refs.modal as any;
    modal.show();
  }

  hide() {
    this.reset;
    const modal = this.$refs.modal as any;
    modal.hide();
  }

  reset() {
    this.rejectionReason = null;
  }

  rejectPlace() {
    const data = {
      id: this.placeId,
      rejectionReason: this.rejectionReason
    };

    this.reject(data, RejectPlace.rejectPlaceTag).then(resp => {

      this.onSaved()
      showToast('Contatto rifiutato con successo!');
    });
  }

  onSaved() {
    this.hide();
    this.$emit('rejected');
  }

  onCancel() {
    this.hide();
    this.$emit('canceled');
  }
}
