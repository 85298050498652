import PlaceParameter from './PlaceParameter';

export default class Competitor extends PlaceParameter {

  static type = 'competitors';
  static parameterType = 'competitors';
  static parameterSingularType = 'competitor';

  name: string;
}
