
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const GoogleMapsApi = require('utils/Constants');

declare global {
  interface Window {
    google?: any;
  }
}

@Component
export default class PlacesAutocomplete extends Vue {
  @Prop({
    required: false,
    default: () => {
      return { country: 'it' };
    }
  })
  options: any;

  @Prop({ default: false }) disabled?: boolean;

  @Prop() value: string;

  @Watch('disabled')
  onDisabledChanged(val, newVal) {
    if (val && !newVal) {
      this.initialized();
    }
  }


  get id() {
    return `autocomplete_${(this as any)._uid}`;
  }

  get placeholder() {
    return this.disabled ? '-' : 'Inserisci una posizione';
  }

  private autocomplete: any = null;

  initialized() {

    if (this.disabled) return;
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.$refs.input,
      this.options
    );
    this.autocomplete.addListener('place_changed', () => {
      this.onPlaceSelected(this.autocomplete.getPlace());
    });
  }

  @Watch('value')
  onValueChange(newValue: string, oldValue: string) {
    console.warn('Changing value in runtime is not supported.');
  }

  onPlaceSelected(place) {
    this.$emit('selected', place);
  }

  get scriptUrl() {
    return `https://maps.googleapis.com/maps/api/js?key=${
      GoogleMapsApi.GOOGLE_API_KEY
    }&libraries=places`;
  }


  mounted() {
    if (!window.google) {
      let plugin = document.createElement('script');
      plugin.setAttribute('src', this.scriptUrl);
      plugin.async = true;
      document.head.appendChild(plugin);
      plugin.onload = () => {
        this.initialized();
      };
    } else {
      this.initialized();
    }
  }
}
