import Model from './Model';
import IAddress from './interfaces/IAddress';

export default class Address extends Model implements IAddress {
  static type = 'addresses';

  address?: string;
  fullAddress?: string;
  zipCode?: string;
  missingStreetNumber: boolean;
}
