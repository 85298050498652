





































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import OrdersMixin from 'mixins/http/OrdersMixin';
import { RequestData } from 'store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import Order from '../../models/Order';
import Icon from 'components/Icon.vue';
import DatePickerModal from 'components/DatePickerModal.vue';
import PlaceAuthorizationMixin from 'mixins/PlaceAuthorizationMixin';
import { Place, User } from '../../models';
import { showToast } from '../../utils/Toast';
const moment = require('moment');

@Component({ components: { DatePickerModal, Icon } })
export default class InstallationDateBanner extends Mixins(
  OrdersMixin,
  PlaceAuthorizationMixin
) {
  static readonly updateInstallationDateTag = 'update_installation_date';

  private installationDateModalName: string = 'installation_date_modal';
  private installationDateModalTitle: string = 'Modifica data installazione';
  private installationDateModalDateLabel: string = 'Data installazione';
  private installationDateModalTimeLabel: string = 'Ora installazione';


  @Getter currentUser?: User;
  @Getter currentPlace?: Place;

  @RegisterHttp(InstallationDateBanner.updateInstallationDateTag)
  updateInstallationDateRequest: RequestData;

  private installationDate?: string = null;

  onDateUpdated(data) {
    if ( data instanceof moment ) {
      this.installationDate = data.toISOString();
      this.update();
    }
  }

  mounted() {
    this.installationDate = this.currentPlace.order.installationDate;
  }

  get formattedHour() {
    const date = this.currentPlace.order.installationDate || new Date();
    let formattedDate = moment(date).format('HH:mm');
    return formattedDate;
  }

  get canShowHelpBanner() {
    return (
      this.currentUser.isTechnician &&
      this.currentPlace.order.orderGears.some(o => !o.serialNumber && o.gear.hasSerialNumber)
    );
  }

  get canChange() {
    return this.canChangeDate(this.currentUser, this.currentPlace);
  }

  get tag() {
    return InstallationDateBanner.updateInstallationDateTag;
  }

  showModal(name, show) {
    const modal = this.$refs[name] as any;
    if (!modal) return;

    if (show) {
      modal.show();
    } else {
      modal.hide();
    }
  }

  onBannerClicked() {
    if (this.canChange) {
      this.showModal(this.installationDateModalName, true);
    }
  }

  update() {
    const data = {
      id: this.currentPlace.id,
      installation_date: this.installationDate
    };
    this.updateInstallationDate(data, this.tag).then(
      resp => {
        this.$emit('dateChanged');
        showToast('Data d\'installazione modificata con successo!');
      }
    );
  }
}
