import { showToast } from 'utils/Toast';

export function downlodadFileFromArrayBuffer(promise: Promise<any>, fileName: string) {
    promise.then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = "_blank";
        link.click();
    },
        err => {
            showToast('Non è stato possibile scaricare il file.', { position: 'bottom-right', type: 'error', duration: 5000 });
        }
    );
}
