






































import { Vue, Component, Model, Prop, Watch } from 'vue-property-decorator';

export interface IDataFormatter {
  fromString(value: string): any;
  toString(value: any): string;
}
class NullDataFormatter implements IDataFormatter {
  fromString(v: string) { return v; }
  toString(v) { return v; }
}

@Component
export default class InlineEditor extends Vue {
  @Prop() value!: any;
  @Prop({ default: '' }) suffix: string;
  @Prop({ default: 'text' }) type: 'number' | 'text' | 'password' | 'email';
  @Prop() placeholder;
  @Prop() maxLength: number;
  @Prop() uppercase: boolean;
  @Prop() lowercase: boolean;
  @Prop({ default: '' }) inputClasses;
  @Prop({ default: false }) textArea: boolean;
  @Prop() dataKeys?: string[];
  @Prop({ default: false }) inputOnly: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop() regExp?: RegExp;
  @Prop({ default: () => new NullDataFormatter() }) formatter: IDataFormatter;

  data = this.value || null;
  editing = false;
  changed = false;
  matchesRegExp = true;

  mounted() {
    this.editing = !this.data;
  }

  get printedData() {
    if (this.data) {
      return `${this.formatter.toString(this.data)} ${this.suffix}`;
    } else {
      return '-';
    }
  }

  get classes() {
    return [this.inputClasses, this.matchesRegExp ? '' : 'text-danger',  !this.disabled ? '' : 'input-disabled'];
  }

  @Watch('value')
  onValueChanged(value, oldValue) {
    if (this.uppercase && value) value.toUpperCase();
    if (this.lowercase && value) value.toLowerCase();
    this.data = value;
  }

  @Watch('data')
  onDataChanged(val, oldVal) {
    if (val !== oldVal) {
      this.validateRegExp();
      this.changed = true;
    }
  }

  get inputStyle() {
    let data =  {};
    if (this.uppercase) {
      data['text-transform'] = 'uppercase';
    }
     if (this.lowercase) {
      data['text-transform'] = 'lowercase';
    }

    return data;
  }

  get outputParams() {
    let payload = {};
    if (this.dataKeys && this.dataKeys.length) {
      for (let i = 0; i < this.dataKeys.length; i++) {
        const key = this.dataKeys[i];
        const isLast = i === this.dataKeys.length - 1;

        if (i === 0) {
          payload[key] = isLast ? this.data : {};
        } else {
          let temp = null;
          for (let y = 0; y < i; y++) {
            temp = temp ? temp[this.dataKeys[y]] : payload[this.dataKeys[y]];
          }
          temp[key] = isLast ? this.data : {};
        }
      }
    } else {
      payload = { ...this.data };
    }

    return payload;
  }

  validateRegExp() {
    this.matchesRegExp = this.regExp ? this.regExp.test(this.data) : true;
  }

  onSave() {
    if (this.uppercase && this.data) this.data = this.data.toUpperCase();
    if (this.changed && (this.matchesRegExp || !this.data)) {
      this.$emit('change', this.outputParams);
    }
    this.editing = false;
    this.changed = false;
  }

  enableEditing() {
    this.editing = true;
  }
}
