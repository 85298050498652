














import { Component, Vue } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';

@Component({ components: { Modal } })
export default class WaitForSubmissionModal extends Vue {

  show() {
      const modal = this.$refs.modal as any;
      modal.show();
    }

    hide() {
      const modal = this.$refs.modal as any;
      modal.hide();
    }

  onCanceled() {
    this.hide();
    this.$emit('canceled');
  }
}
