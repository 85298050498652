
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UsersMixin extends Vue {

  getUsers(data: any, tag) {
    return this.$httpGet( this.$urlFor('api_v1_users', { format: 'json', ...data }), tag);
  }

  getUser(id: number, tag) {
    return this.$httpGet( this.$urlFor('api_v1_users', { format: 'json', id}), tag);
  }

  destroyUser(id, tag) {
    return this.$httpDelete(this.$urlFor('api_v1_user', id ), tag);
  }

  createUser(data: any, tag) {
    return this.$httpPost(this.$urlFor('api_v1_users'), tag, data);
  }

  updateUser(data: any, tag) {
     return this.$httpPut(this.$urlFor('api_v1_user', { id: data.id }), tag, data);
  }
}
