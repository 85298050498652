import Model from './Model';
import IContact from './interfaces/IContact';
import Address from './Address';

export default class Contact extends Model implements IContact {
  static type = 'contacts';

  firstName?: string;
  lastName?: string;
  fiscalCode?: string;
  birthDate?: string;
  birthPlace?: string;
  phone?: string;
  role?: string;
  address?: Address;

  fullname(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
