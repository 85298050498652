import PlaceParameter from './PlaceParameter';

export default class PlaceType extends PlaceParameter {
  static type = 'placeTypes';
  static parameterType = 'place_types';
  static parameterSingularType = 'place_type';

  name?: string;
  disabled?: string;
}
