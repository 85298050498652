


























































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import InlineEditor, { IDataFormatter } from 'components/InlineEditor.vue';
import InlinePlacesAutocomplete from 'components/Google/InlinePlacesAutocomplete.vue';
import { Place } from 'models';
import * as moment from 'moment';

@Component({ components: { InlineEditor, InlinePlacesAutocomplete } })
export default class PlaceContactData extends Vue {
  @Prop({ required: true })
  place: Place;
  private dateFormatter: IDataFormatter = {
    fromString(value) {
      return value;
    },
    toString(value) {
      return moment(value).format('DD/MM/YYYY');
    }
  };

  companyNamespace = ['company'];
  companyContactNamespace = [...this.companyNamespace, 'contact'];

  onDataUpdated(data) {
    this.$emit('updated', data);
  }

  onCompanyStreetNumberPresenceChanged() {
    this.$emit('updated', {
      company: {
        address: {
          missingStreetNumber: !this.place.company.address.missingStreetNumber
            ? '1'
            : '0'
        }
      }
    });
  }

  onContactStreetNumberPresenceChanged() {
    this.$emit('updated', {
      company: {
        contact: {
          address: {
            missingStreetNumber: !this.place.company.contact.address
              .missingStreetNumber
              ? '1'
              : '0'
          }
        }
      }
    });
  }

  @Prop({ default: false })
  disabled: boolean;

  emailRegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  vatCodeRegExp = new RegExp(
    '(^[0-9]{11}$)|(^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$)',
    'i'
  );
  fiscalCodeRegExp = new RegExp(
    '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9A-Z]{3})[a-zA-Z]{1}$',
    'i'
  );
  sdiRegExp = new RegExp('^[0-9a-zA-Z]{7}$', 'i');
}
