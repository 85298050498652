




import { Vue, Component, Prop } from 'vue-property-decorator';
import PlacesAutocomplete from './PlacesAutocomplete.vue';

@Component({ components: { PlacesAutocomplete } })
export default class InlinePlacesAutocomplete extends Vue {
  @Prop() options: any;
  @Prop() dataKeys: any;
  @Prop() value: string;
  @Prop({default: false}) disabled?: boolean;

  onPlaceSelected(place) {
    this.$emit('selected', this.outputParams(place));
  }

  outputParams(data) {
    let payload: any = {};
    if (this.dataKeys && this.dataKeys.length) {
      for (let i = 0; i < this.dataKeys.length; i++) {
        const key = this.dataKeys[i];
        const isLast = i === this.dataKeys.length - 1;

        if (i === 0) {
          this.formatData(isLast, data, payload, key);
        } else {
          let temp = null;
          for (let y = 0; y < i; y++) {
            temp = temp ? temp[this.dataKeys[y]] : payload[this.dataKeys[y]];
          }
          this.formatData(isLast, data, temp, key);
        }
      }
    } else {
      payload = data;
    }
    return payload;
  }

  formatData(isLast: boolean, data: any, output: any, key: string) {
    output[key] = isLast ? data.address_components : {};
    if (isLast) {
      output.address = { fullAddress: data.formatted_address };
      output.latitude = data.geometry?.location?.lat();
      output.longitude = data.geometry?.location?.lng();
    }
  }

}
