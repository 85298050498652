

















import { Component, Mixins, Prop } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import OrdersMixin from 'mixins/http/OrdersMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import NumberInput from 'components/NumberInput.vue';
import SpecialConditionSelect from 'components/SpecialCondition/Select.vue';

@Component({components: { InlineEditor, SpecialConditionSelect, NumberInput }})
export default class CreateOrderSpecialConditionItem extends Mixins(OrdersMixin) {

  static readonly createTag = 'create_order_special_condition';

  @RegisterHttp(CreateOrderSpecialConditionItem.createTag) createRequestd: RequestData;

  @Prop({required: true})
  orderId: string;

  data: any = {};
  editing: boolean = false;

  get valid() {
    return this.data.special_condition_id;
  }

  reset() {
    this.data.special_condition_id = undefined;
  }

  onParameterChanged(value) {
    this.data = {
      order_id: this.orderId,
      ...this.data,
      ...value
    };
  }

  onSaveClicked() {

    if (!this.valid) {
      return;
    }

     this.createOrderSpecialCondition(this.data, CreateOrderSpecialConditionItem.createTag)
     .then(resp => {
        this.$emit('created', resp);
        this.reset();
        this.editing = false;
     });
  }

}
