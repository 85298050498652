import Model from './Model';
import IActivity from './interfaces/IActivity';

export default class Activity extends Model implements IActivity {

  static type = 'activities';

  sent: boolean;
  read: boolean;
  actionType?: string;
  resourceType?: string;
  payload: any;
}
