















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';
import { RequestData } from 'store/types/RequestStore';
import PlaceSharingsMixin from 'mixins/http/PlaceSharingsMixin';
import { showToast } from 'utils/Toast';
import { User } from 'models';

const http = namespace('http');

@Component({})
export default class PlaceSharingItem extends Mixins(PlaceSharingsMixin) {
  @Getter currentPlace;
  @Getter currentUser;
  @http.Action('register') registerCall;
  @http.Getter status: (key: string) => RequestData;
  @Action addSharee: ((sharee: User) => void );
  @Action removeSharee: ((sharee: User) => void );

  @Prop() user: User;

  get selected() {
    return this.currentPlace.sharees.map(o => o.id).includes(this.user.id);
  }

  set selected(value) {
    if (value) {
      this.addPlaceSharing();
    } else {
      this.destroyPlaceSharing();
    }
  }

  get loading() {
    return this.status[this.addPlaceSharingTag]?.loading || this.status[this.removePlaceSharingTag]?.loading;
  }

  get addPlaceSharingTag() {
    return `share_place_${this.currentPlace.id}_to_sharee_${this.user.id}`;
  }
  get removePlaceSharingTag() {
    return `unshare_place_${this.currentPlace.id}_to_sharee_${this.user.id}`;
  }

  addPlaceSharing() {
    this.registerCall(this.addPlaceSharingTag);
    this.sharePlace(this.payload, this.addPlaceSharingTag).then(
      resp => {
        this.addSharee(this.user);
        showToast(
          `Contatto condiviso a ${this.user.fullname()} correttamente`
        );
      }
    );
  }

  get payload() {
    return {
      place_id: this.currentPlace.id,
      sharee_id: this.user.id,
      sharer_id: this.currentUser.id
    };
  }

  destroyPlaceSharing() {
    this.registerCall(this.removePlaceSharingTag);
    this.unsharePlace(this.payload, this.removePlaceSharingTag).then(
      resp => {
        this.removeSharee(this.user);
        showToast(
          `Condivisione del contatto con ${this.user.fullname()} interrotta correttamente`
        );
      }
    );
  }
}
