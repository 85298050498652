









































import ConfirmModal from 'components/ConfirmModal.vue';
import Icon from 'components/Icon.vue';
import CreatePlace from 'components/Place/Create.vue';
import WaitForSubmission from 'components/Place/WaitForSubmission.vue';
import CreateVisit from 'components/Visit/Create.vue';
import PlaceAuthorizationMixin from 'mixins/PlaceAuthorizationMixin';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { Place, User } from 'models';
import { showToast } from 'utils/Toast';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PlaceEdit from '../views/Place/Edit.vue';
import { downlodadFileFromArrayBuffer } from 'models/DownloadHelper';

@Component({ components: { CreateVisit, CreatePlace, Icon, WaitForSubmission, ConfirmModal } })
export default class AgentBottomBar extends Mixins(PlacesMixin, PlaceAuthorizationMixin) {
  readonly createVisitModalName = 'createVisitModal';
  readonly createPlaceModalName = 'createPlaceModal';
  readonly waitForSubmissionModalName = 'wait_for_submission_modal';

  readonly confirmCandidatePlaceModalName = 'confirm_candidate_place';
  readonly confirmCandidatePlaceModalTitle = 'Inviare alla direzione?';
  readonly confirmCandidatePlaceModalContent = 'Sei sicuro di voler inviare alla direzione il contatto? Verifica i dati inseriti e se sei certo di voler continuare premi "Conferma"';

  @Action('http/register') registerCall;
  @Getter currentPlace?: Place;
  @Getter currentUser?: User;
  @Action updateCurrentPlace;
  @Action setCurrentPlace;

  showModal(name, show) {
    const modal = this.$refs[name] as any;
    if (!modal) return;

    if (show) {
      modal.show();
    } else {
      modal.hide();
    }
  }

  get canComplete() {
    if (!this.currentPlace) return false;
    return this.canCompletePlace(this.currentUser, this.currentPlace);
  }

  get canCandidate() {
    if (!this.currentPlace) return false;
    return this.currentPlace?.isLead || this.currentPlace?.isRejected;
  }

  get currentUserOwnsPlace(){
    return this.currentUser?.id === this.currentPlace?.agent?.id;
  }

  get canDownloadContractPreview(){
    return this.currentUserOwnsPlace && this.currentPlace?.version === 2 && this.canCandidate;
  }

  get showCandidate() {
    if (!this.currentPlace ) return false;
    return this.currentUserOwnsPlace && this.canCandidate;
  }

  get placeId() {
    return this.currentPlace?.id;
  }

  candidatePlaceTag() {
    return `${PlaceEdit.candidatePlaceTag}_${this.placeId}`;
  }

  completePlaceTag() {
    return `${PlaceEdit.completePlaceTag}_${this.placeId}`;
  }

  downloadContractPreviewTag(){
    return `${PlaceEdit.downloadContractPreviewTag}_${this.placeId}`;
  }

  getContractPdfPreview(){
    this.registerCall(this.downloadContractPreviewTag());
    const promise = this.downloadContractPreview(this.placeId, this.downloadContractPreviewTag());
    const pdfName = `Proposta di contratto di ${Place.contractTypes.filter((el) => el.id === this.currentPlace?.order?.newContractType)[0]?.name} - ${this.currentPlace.name}`;
    downlodadFileFromArrayBuffer(promise, pdfName);
  }

  candidatePlace() {
    this.registerCall(this.candidatePlaceTag());
    const data = {
      id: this.placeId
    };

    let promise = this.currentPlace.isRejected ? this.recandidate(data, this.candidatePlaceTag()) : this.candidate(data, this.candidatePlaceTag());
    promise.then(resp => {
      this.updateCurrentPlace({status: resp.status});
      this.showModal(this.waitForSubmissionModalName, true );
    }, err => {
      showToast('Non è stato possibile segnalare il contatto alla Direzione', {position: 'bottom-right', type: 'error', duration: 5000});
    });
  }

  completePlace() {

    this.registerCall(this.completePlaceTag());
    const data = {
      id: this.placeId
    };

    this.complete(data, this.completePlaceTag()).then(resp => {

      this.setCurrentPlace(resp);
      showToast('Contatto completato con successo!');

    }, err => {
      showToast('Non è stato possibile completare il contatto!', {position: 'bottom-right', type: 'error', duration: 500});
    });
  }

  onCreateVisitConfim(data) {
    showToast('Visita registrata con successo!');
  }

  onCreatePlaceConfim(data) {
    showToast('Contatto creato con successo!"');
    this.$router.push({name: 'place-edit', params: { place_id: data.id}});
  }

  get isPlaceEdit() {
    return this.$route.name === 'place-edit';
  }

  showToast(message, type = 'success') {
    Vue.toasted.show(message, {
      position: 'bottom-right',
      type: type,
      duration: 10000,
      keepOnHover: true
    } as any);
  }
}
