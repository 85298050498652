


















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import { RequestData } from '/store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { Locality } from 'models';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class PlaceSelect extends Mixins(ObjectPathMixin, PlacesMixin) {
  static readonly getPlacesTag = 'get_places_tag';

  @Prop() idSelected?: string | number;
  @Prop() params: any;
  @Prop() dataKeys?: Array<string>;
  @Prop() classes?: string;
  @Prop({ default: false }) actsLikeFilter?: boolean;
  @Prop({ default: false }) disabled?: boolean;

  @RegisterHttp(PlaceSelect.getPlacesTag) http: RequestData;

  private items: any = null;
  private id: any = this.idSelected;
  private displayProperty: string = 'name';

  get queryParams() {
    return { ...this.params, include: 'name', per: 500 };
  }

  get all() {
    return { id: 'all', name: 'Tutte' };
  }

  mounted() {
    this.loadItems();
  }
  onSearch(obj, statechanger) {
    const filter = {
      filter: {
        term: obj
      }
    };

    this.loadItems(filter);
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
  }

  loadItems(searchParams = null) {
    let data = {
      ...searchParams,
      ...this.queryParams
    };
    this.getPlaces(data, PlaceSelect.getPlacesTag).then(
      resp => {
        this.items = resp.map(o => {
          return { id: o.id, name: o.name };
        });
        if (this.actsLikeFilter) {
          this.items.unshift(this.all);
        }
        if (!this.id) {
          this.id = this.$route.query.locality
            ? this.$route.query.locality
            : this.items[0].id;
            this.onItemSelected(this.items.find(o => o.id === this.id));
        }
      }
    );
  }
}
