const routes = [
  {
    path: '/places',
    component: () => import('views/Place/List.vue'),
    name: 'places',
    meta: { role: ['anyone'] },
  },
  {
    path: '/places/map',
    component: () => import('views/Place/Map.vue'),
    name: 'places_map',
    meta: { role: ['admin', 'super_admin', 'manager'] },
  },
  {
    path: '/places/create',
    component: () => import('views/Place/Create.vue'),
    name: 'place-create',
    meta: { role: ['anyone'] },
  },
  {
    path: '/places/:place_id',
    component: () => import('views/Place/Edit.vue'),
    name: 'place-edit',
    meta: { role: ['anyone'] },
  },
];

export default routes;
