


















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import { RequestData } from '/store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import PlaceParametersMixin from 'mixins/http/PlaceParametersMixin';
import { PlaceOwnershipType } from '../../models';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class PlaceOwnershipTypeSelect extends Mixins(PlaceParametersMixin, ObjectPathMixin) {
  static readonly getPlaceOwnershipTypesTag = 'get_place_ownership_types';
  @RegisterHttp(PlaceOwnershipTypeSelect.getPlaceOwnershipTypesTag) http: RequestData;

  @Prop() idSelected?: string | number;
  @Prop() params;
  @Prop() dataKeys ?: string;
  @Prop({default: false}) disabled?: boolean;

  private items: any = null;

  get queryParams() {
    return {per: 1000 , ...this.params};
  }

  mounted() {
      this.loadItems();
  }

  outputParams(data) {
    return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', this.outputParams(data));
  }

  loadItems() {
    this.getPlaceParameters(PlaceOwnershipType, this.queryParams, PlaceOwnershipTypeSelect.getPlaceOwnershipTypesTag).then(
      resp => {
        this.items = resp;
      }
    );
  }
}
