import PlaceParameter from './PlaceParameter';

export default class PlaceRecommendationType extends PlaceParameter {

  static type = 'placeRecommendationTypes';
  static parameterType = 'place_recommendation_types';
  static parameterSingularType = 'place_recommendation_type';

  name?: string;
}
