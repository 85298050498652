


























































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { User } from 'models';
import UsersMixin from 'mixins/http/UsersMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import ModelSelect from 'components/ModelSelect.vue';
import SearchBar from 'components/SearchBar.vue';
import { Getter, Action, namespace } from 'vuex-class';
import Paginator from '@monade/vue-components/src/components/Paginator.vue';
import { showToast } from 'utils/Toast';
import PlaceSharingItem from 'components/PlaceSharing/Item.vue';
import Icon from 'components/Icon.vue';

const http = namespace('http');

@Component({
  components: { Modal, ModelSelect, SearchBar, Paginator, PlaceSharingItem, Icon}
})
export default class ShareToAgent extends Mixins(UsersMixin) {
  static readonly getAgentsTag = 'get_agents';
  private selectedAgent?: User = null;
  private agents?: Array<User> = Array();
  private search: string = '';
  private page = 1;

  @Getter currentUser;

  @RegisterHttp(ShareToAgent.getAgentsTag) getAgentsRequest: RequestData;

  show() {
    this.loadAgents();
    const modal = this.$refs.modal as any;
    modal.show();
  }

  onPageChange(page) {
    this.page = page;
    this.loadAgents();
  }

  onSearch(search) {
    this.search = search;
    this.page = 1;
    this.loadAgents({ term: search });
  }

  isSelected(agent) {
    return this.selectedAgent && agent.id === this.selectedAgent.id;
  }

  hide() {
    const modal = this.$refs.modal as any;
    modal.hide();
  }

  get params() {
    let data: any = {
      page: this.page,
      filter: {
        role: ['agent'],
        not: this.currentUser.id
      }
    };

    return data;
  }

  loadAgents(searchParams: any = {}) {
    let params = this.params;

    if (searchParams) {
      params.filter.term = searchParams.term;
    }

    this.getUsers(params, ShareToAgent.getAgentsTag).then(
      resp => {
        this.agents = resp;
      }
    );
  }

  onDone() {
    this.hide();
  }

  onCancel() {
    this.hide();
    this.$emit('canceled');
  }
}
