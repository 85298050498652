import PlaceParameter from './PlaceParameter';

export default class ContractDurationOption extends PlaceParameter {

  static type = 'contractDurationOptions';
  static parameterType = 'contract_duration_options';
  static parameterSingularType = 'contract_duration_option';

  static types: Array<{ name: string; id: string }> =
    [
      { name: "kg", id: "kg" },
      { name: "mesi", id: "months" },
    ];

  number: number;
  type: string;
  disabled: boolean;
}