




























































































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { User } from "models";
import NotificationDropdown from "components/Notification/Dropdown.vue";
import { State, Getter, Action, Mutation } from "vuex-class";
import store from "store";
import ActivityRecipientList from "components/ActivityRecipient/List.vue";
import ClickOutside from "directives/ClickOutside";

@Component({
  components: { ActivityRecipientList, NotificationDropdown },
  directives: { ClickOutside },
})
export default class Navbar extends Vue {
  @Getter currentUser;
  @Getter token;

  dropdownOpen: boolean = false;
  notificationsOpen: boolean = false;

  get userRole() {
    return this.currentUser ? User.getRoleLabel(this.currentUser.role) : "";
  }

  get isAdmin() {
    return (
      this.currentUser?.role === "admin" ||
      this.currentUser?.role === "super_admin"
    );
  }

  get isManager() {
    return this.currentUser?.role === 'manager';
  }

  _logout() {
    store.dispatch("logout").then(() => {
      window.location.reload();
    });
  }

  onOutsideClick() {
    this.dropdownOpen = false;
  }
}
