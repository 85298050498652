







import Navbar from 'components/Navbar.vue';
import { Component, Vue } from 'vue-property-decorator';
import Container from 'views/Container.vue';
import { Getter } from 'vuex-class';
import { User } from 'models';

@Component( { components: { Navbar, Container } })
export default class App extends Vue {
  @Getter currentUser?: User;

  mounted() {
    window.addEventListener('focus', this.ensureUserIsLogged);
  }

  async ensureUserIsLogged() {
    if (!this.currentUser) {
      return
    }
    this.$store
      .dispatch('loadCurrentUser', this.currentUser)
      .catch(() => {
        this.$store.dispatch('reauthenticate');
      });
  }

  beforeDestroy() {
    window.removeEventListener('focus', this.ensureUserIsLogged);
  }
}
