import PlaceParameter from './PlaceParameter';

export default class Gear extends PlaceParameter {

  static type = 'gears';
  static parameterType = 'gears';
  static parameterSingularType = 'gear';

  name?: string;
  hasSerialNumber?: boolean;
}
