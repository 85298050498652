






















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import OrderMixins from 'mixins/http/OrdersMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Icon from 'components/Icon.vue';
import IOrderSpecialCondition from '../../models/interfaces/IOrderSpecialCondition';

@Component({ components: { InlineEditor, Icon } })
export default class OrderSpecialConditionItem extends Mixins(OrderMixins) {
  static readonly deleteOrderSpecialConditionTag = 'delete_order_special_condition';
  static readonly updateOrderSpecialConditionTag = 'update_order_special_condition';

  @Prop({ required: true })
  item: IOrderSpecialCondition;

  @Prop({ default: false }) disabled?: boolean;

  @RegisterHttp(OrderSpecialConditionItem.deleteOrderSpecialConditionTag)
  deleteRequest: RequestData;
  @RegisterHttp(OrderSpecialConditionItem.updateOrderSpecialConditionTag)
  updateRequest: RequestData;

  data = this.item;

  @Watch('item')
  onParameterValueChanged(val, oldVal) {
    this.data = val;
  }

  onParameterChanged(value) {
    const data = {
      id: this.data.id,
      ...value
    };

    this.updateOrderSpecialCondition(data, OrderSpecialConditionItem.updateOrderSpecialConditionTag).then(
      resp => {
        this.data = resp;
      }
    );
  }

  onDeleteClicked() {
    this.destroyOrderSpecialCondition(
      this.data.id,
      OrderSpecialConditionItem.deleteOrderSpecialConditionTag
    ).then(
      resp => {
        this.$emit('deleted', resp);
      }
    );
  }
}
