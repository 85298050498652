
























import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ActivityRecipientsMixin from 'mixins/http/ActivityRecipientsMixin';
import { RequestData } from '/store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import { ActivityRecipient, User, Place } from 'models';
import { Getter } from 'vuex-class';

@Component
export default class NotificationList extends Mixins(ActivityRecipientsMixin) {
  static readonly getNotificationsTag = 'get_notifications_tag';
  static readonly setNotificationReadTag = 'set_notification_read_tag';

  @RegisterHttp(NotificationList.getNotificationsTag)
  getNotificationRequest: RequestData;

  @Prop()
  open: boolean;

  @RegisterHttp(NotificationList.setNotificationReadTag)
  setNotificationReadRequest: RequestData;

  static readonly unreadTag = 'unread_activity_recipients_tag';
  @RegisterHttp(NotificationList.unreadTag) unreadActivityRecipientsRequests: RequestData;

  unreadCount: number = 0;

  @Watch('open')
  onOpenChanged(val, newVal) {

    if (val && this.unreadCount > 0) {
      this.unreadAllActivityRecipients({}, NotificationList.unreadTag)
        .then(resp => {
          this.items = resp;
          this.$emit('count', this.unreadActivityRecipientsRequests.meta.unread);
        });
    }
  }


  @Getter currentUser: User;

  private page = 1;

  private notificationsPolling = 30 * 1000;

  private pollingInterval = null;

  private items: ActivityRecipient[] = [];

  showMore() {
   return this.getNotificationRequest && this.getNotificationRequest.meta && this.page < this.getNotificationRequest.meta.totalPages;
  }

  onNotificationClick(item) {

    const routerParams = this.link(item);
    if (Object.keys(routerParams).length) this.$router.push(routerParams);
  }

  get getParams() {
    let params = {
      include: 'activity.*',
      page: this.page,
      filter: {
        user: this.currentUser.id,
      }
    };

    return params;
  }

  get updateParams() {
    let params = {
      include: 'activity.*',
      read: true,
    };

    return params;
  }

  action(item) {

    const payload = item?.activity?.payload;
    switch (payload?.currentEvent) {
      case 'assign':
      case 'reassign':
        return 'ti è stato assegnato';
      case 'candidate':
        return 'ti è stato segnalato';
      case 'recandidate':
        return 'ti è stato ri-segnalato';
      case 'accept':
        return 'è stato accettato';
      case 'reject':
        return 'è stato rifiutato';
      case 'complete':
        return 'è stato completato';
      case 'schedule':
        return 'è stato programmato';
      case 'reschedule':
        return 'è stato riprogrammato';
      case 'notify':
        return `è stato spostato nello stato ${this.placeStatus(payload.toState)}`;
    }
    return '';
  }

  resource(item) {
    switch (item?.activity?.resourceType) {
      case 'Place':
        return `Il contatto ${item.activity.resource.name}`;
        break;
    }
    return '';
  }

  placeStatus(status) {
    return Place.getStatusLabel(status);
  }

  link(item) {
     switch (item?.activity?.resourceType) {
      case 'Place':
        let payload: any = {};
        if (this.$route.params?.place_id !== item?.activity?.resource.id) payload.params =  { place_id: item?.activity?.resource.id };
        if (this.$route.name !== 'place-edit') payload.name = 'place-edit';
        return payload;
        break;
     }
  }

  from(item) {
    switch (item?.activity?.user.role) {
      case 'agent':
        return `da ${item?.activity?.user?.fullname()}`;
      break;
      case 'manager':
        return `dalla Direzione`;
      break;
      case 'executive':
        return `dall'Amministrazione`;
      case 'admin':
        return `dall'Admin di sistema`;
      case 'technician':
        return `da ${item?.activity?.user?.fullname()}`;
      break;
    }

    return '';
  }

  mounted() {

    setTimeout(() => {
      this.loadNotifications();
      this.setUpdateInterval();
    }, 500);
  }

  setUpdateInterval() {

    this.pollingInterval = setInterval(() => {

      if (this.open) {
        this.items = [];
        this.loadNotifications();
      }

    }, this.notificationsPolling);
  }

  loadMore() {
    this.page++;
    this.loadNotifications();
  }

  beforeDestroy() {
    clearInterval(this.pollingInterval);
  }

  loadNotifications() {
    if (this.page === 1) this.items = [];
    this.getActivityRecipients(this.getParams, NotificationList.getNotificationsTag)
    .then(resp => {
      this.items.push(...resp);
      this.unreadCount = this.getNotificationRequest.meta.unread;
      this.$emit('count', this.unreadCount);

    }, err => {
    });
  }
}
