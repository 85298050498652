import VueRouter, { RouteConfig } from 'vue-router';
import store from 'store';
import User from 'models/User';
import StyleguideRoutes from 'router/Styleguide';
import UserRoutes from 'router/User';
import PlaceRoutes from 'router/Place';
import VideoTutorialRoutes from 'router/VideoTutorial';
import PlaylistRoutes from 'router/Playlist';

import AuthenticationRoutes from 'router/Authentication';

// Roles: ['admin', 'manager', 'executive', 'agent']

const routes: RouteConfig[] = [
  { path: '/', redirect: 'dashboard', name: 'root', meta: { role: ['anyone'] } },
  { path: '/dashboard', component: () => import('views/Dashboard.vue'), name: 'dashboard', meta: { role: ['anyone'] } },
  { path: '/settings', component: () => import('views/Settings.vue'), name: 'settings', meta: { role: ['admin', 'manager'] } },
  { path: '/place_parameters', component: () => import('views/PlaceParameter/Show.vue'), name: 'place_parameters', meta: { role: ['admin', 'manager'] } },
  { path: '/forgot_password', component: () => import('views/Password/Forgot.vue'), name: 'forgot_password', meta: { noAuth: true } },
  { path: '/reset_password', component: () => import('views/Password/Reset.vue'), name: 'reset_password', meta: { noAuth: true } },
  {
    path: '/clients/map',
    component: () => import('views/ClientImport/Map.vue'),
    name: 'clients_map',
    meta: { role: ['admin', 'super_admin', 'manager'] },
  },
  ...StyleguideRoutes,
  ...AuthenticationRoutes,
  ...UserRoutes,
  ...PlaceRoutes,
  ...VideoTutorialRoutes,
  ...PlaylistRoutes,
  { path: '*', component: () => import('views/NotFound.vue'), name: '404', meta: { role: ['anyone'] } },
];

const router = new VueRouter({
  base: '/',
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const { role, noAuth } = to?.matched[0]?.meta || {};
  store.dispatch('authenticate')
    .then(
      (user: User) => {
        if (to?.matched?.some(record => record.meta.noAuth)) {
          next('/');
          return;
        }

        if (role?.includes(user.role) || role?.includes('anyone')) {
          // if authorized
          next();
          return;
        } else {
          // if not it is redirected
          next(false);
          return;
        }
      },
      () => {
        if (to?.matched?.every(record => record.meta.noAuth)) {
          next();
          return;
        } else {
          next(`/login?redirect=${encodeURIComponent(window.location.toString())}`);
        }
      }
    );
});

export default router;
