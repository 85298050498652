import Model from "./Model";
import IPlace from "./interfaces/IPlace";
import { Company, User, Contact } from "models";
import Address from "./Address";
import Order from "./Order";

export default class Place extends Model implements IPlace {
  static type = "places";

  static statuses: Array<{ dashboardName: string; name: string; id: string }> =
  [
    { dashboardName: "Bozze", name: "Bozza", id: "draft" },
    { dashboardName: "Da assegnare", name: "Da assegnare", id: "pending" },
    { dashboardName: "Assegnati", name: "Assegnato", id: "lead" },
    { dashboardName: "Da accettare", name: "Da accettare", id: "prospect" },
    { dashboardName: "Rifiutati", name: "Rifiutato", id: "rejected" },
    {
      dashboardName: "Da programmare",
      name: "Da programmare",
      id: "customer",
    },
    { dashboardName: "Abbandonati", name: "Abbandonato", id: "discarded" },
    { dashboardName: "Programmati", name: "Programmato", id: "scheduled" },
    { dashboardName: "Completati", name: "Completato", id: "completed" },
  ];

  static contractTypes: Array<{ name: string; id: string }> = [
    { name: "Somministrazione e comodato con sconto", id: "administration_and_commodate" },
    { name: "Somministrazione", id: "administration" },
    { name: "Noleggio", id: "rent_and_administration" },
  ];

  static emptyModel: IPlace = {
    contact: {},
    company: {},
  };

  name: string;
  status: string;
  previousBusinessName: string;
  newCustomer: boolean;
  latitude?: string | number;
  longitude?: string | number;
  company?: Company;
  contact?: Contact;
  address?: Address;
  openingStatus?: string;
  agent?: User;
  user?: User;
  lat?: number;
  lon?: number;
  sharees?: User[];
  consignee?: User;
  order?: Order;
  closingDays?: string[];
  google_maps_location?: any[];
  isInTarget?: boolean;
  version: number;

  hasDeletableStatus() {
    return (
      this.status === "draft" ||
      this.status === "pending" ||
      this.status === "lead"
    );
  }

  static getStatusLabel(status: string): string | undefined {
    return this.statuses.find((o) => o.id === status)?.name;
  }

  static getDashboardLabel(status: string): string | undefined {
    return this.statuses.find((o) => o.id === status)?.dashboardName;
  }

  get formattedStatus() {
    return Place.getStatusLabel(this.status);
  }

  get isLead() {
    return this.status === "lead";
  }

  get isPending() {
    return this.status === "pending";
  }

  get isDraft() {
    return this.status === "draft";
  }

  get isProspect() {
    return this.status === "prospect";
  }

  get isCompleted() {
    return this.status === "completed";
  }

  get isRejected() {
    return this.status === "rejected";
  }

  get isScheduled() {
    return this.status === "scheduled";
  }

  get isCustomer() {
    return this.status === "customer";
  }

  isOneOf(statuses: string[]) {
    return statuses.includes(this.status);
  }

  get isNewBusiness() {
    return this.openingStatus === "new_business";
  }

  get isExistingBusiness() {
    return this.openingStatus === "existing_business";
  }
}
