import PlaceParameter from './PlaceParameter';

export default class Accessory extends PlaceParameter {

  static type = 'accessories';
  static parameterType = 'accessories';
  static parameterSingularType = 'accessory';

  name?: string;
}
