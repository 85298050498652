import axios from 'axios';
import Vue from 'vue';
import Router from 'router/index';
import store from "store";

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.getAttribute('content');
} else {
    console.error('CSRF token not found');
}

axios.interceptors.response.use(
  (r) => r, (error) => {
    if (error?.response?.status === 422 || error?.response.status === 404) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      if ( Router.currentRoute.name !== 'login') {
        store.dispatch('reauthenticate');
      }
      return Promise.reject(error);
    }

    const message = error.response?.data && (error.response.data.errors && error.response.data.errors.message || error.response.data.message) || error.message;
    Vue.toasted.show(message, {
      position: 'bottom-right', type: 'error', icon: 'error', duration: 10000, keepOnHover: true,
      action: {
        text: 'Chiudi',
        onClick(e, toastObject) {
          toastObject.goAway(0);
        }
      }
    } as any);
    return Promise.reject(error);
  }
);
