import { Component, Mixins } from 'vue-property-decorator';
import RequestsMixin from 'mixins/http/RequestsMixin';

@Component
export default class OrdersMixin extends Mixins(RequestsMixin) {

  destroyOrderGear(id, tag) {
    return this.$httpDelete(this.$urlFor('api_v1_order_gear', id), tag);
  }

  updateOrderGearTechnicianData(data: any, tag) {
    return this.$httpPut(this.$urlFor('api_v1_order_gear_update_technician_data', { id: data.id }), tag, data);
  }

  createOrderGear(data: any, tag) {
    return this.$httpPost(this.$urlFor('api_v1_order_gears'), tag, data);
  }

  updateOrderGear(data: any, tag) {
    return this.$httpPut(this.$urlFor('api_v1_order_gear', { id: data.id }), tag, data);
  }

  createOrderAccessory(data: any, tag) {
    return this.$httpPost(this.$urlFor('api_v1_order_accessories'), tag, data);
  }

  updateOrderAccessory(data: any, tag) {
    return this.$httpPut(this.$urlFor('api_v1_order_accessory', { id: data.id }), tag, data);
  }

  destroyOrderAccessory(id, tag) {
    return this.$httpDelete(this.$urlFor('api_v1_order_accessory', id), tag);
  }

  createOrderSpecialCondition(data: any, tag) {
    return this.$httpPost(this.$urlFor('api_v1_order_special_conditions'), tag, data);
  }

  updateOrderSpecialCondition(data: any, tag) {
    return this.$httpPut(this.$urlFor('api_v1_order_special_condition', { id: data.id }), tag, data);
  }

  destroyOrderSpecialCondition(id, tag) {
    return this.$httpDelete(this.$urlFor('api_v1_order_special_condition', id), tag);
  }

  updateInstallationDate(data: any, tag) {
    return this.$httpPut(this.$urlFor('reschedule_api_v1_place', { id: data.id }), tag, data);
  }

}
