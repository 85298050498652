

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ObjectPathMixin extends Vue {
  outputObject(data, dataKeys) {
    let payload = {};
     if (dataKeys && dataKeys.length) {
       for (let i = 0; i < dataKeys.length;  i++) {
         const key = dataKeys[i];
         const isLast = i === dataKeys.length - 1;
         if (i === 0) {
           payload[key] = isLast ? data.id : {};
         } else {
           let temp = null;
           for ( let y = 0 ; y < i; y++) {
             temp = temp ? temp[dataKeys[y]] : payload[dataKeys[y]];
           }
           temp[key] = isLast ? data.id : {};
         }
       }
     } else {
         payload = data.id;
     }
     return payload;
   }
}
