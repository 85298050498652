

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Visit } from 'models';

@Component
export default class TableRow extends Vue {
  @Prop()
  visit!: Visit;

  opened: boolean = false;
}
