




















































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Visit } from 'models';
import { Getter } from 'vuex-class';
import VisitsMixin from 'mixins/http/VisitsMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Modal from 'components/Modal.vue';
import ModelSelect from 'components/ModelSelect.vue';
import PlaceSelect from 'components/Place/Select.vue';
import Loader from 'vue-spinner/src/PulseLoader.vue';
import TypologySelect from './TypologySelect.vue';
import 'moment';
const moment = require('moment');

@Component({
  components: { Modal, ModelSelect, Loader, PlaceSelect, TypologySelect }
})
export default class VisitCreate extends Mixins(VisitsMixin) {
  @Prop({
    default: () => {
      return {};
    }
  })
  resource?: Visit;

  @Getter
  currentUser;

  @Prop({ default: false })
  lockPlaceSelection?: boolean;

  data: any = this.resource;

  private visit = { ...this.resource };
  private httpTag = 'edit_visit_modal';

  @RegisterHttp('edit_visit_modal') http: RequestData;

  mounted() {
    this.onPlaceSelected(this.resource.placeId);
    this.onTypologySelected(this.resource.typology);
  }

  get valid() {
    return this.data.date && this.data.place_id && this.data.text;
  }

  title(): string {
    return 'Modifica visita';
  }

  show() {
    const modal = this.$refs.modal as any;
    modal.show();
  }

  onPlaceSelected(value) {
    this.$set(this.data, 'place_id', value);
  }

  onTypologySelected(value) {
    this.$set(this.data, 'typology', value);
  }

  hide() {
    const modal = this.$refs.modal as any;
    modal.hide();
  }

  onDateChanged(data) {
    this.$set(this.data, 'date', moment(data).format('YYYY-MM-DD'));
  }

  reset() {
    this.resource = null;
    this.data = null;
  }

  get updateParams() {
    let data = {
      ...this.data,
    };
    return data;
  }

  save() {

    this.updateVisit(this.updateParams, this.httpTag)
      .then(
        resp => {
          this.onSaved(resp);
        },
        err => { }
      );
  }

  onSaved(resp) {
    this.hide();
    this.$emit('updated', resp);
  }

  onCancel() {
    this.hide();
    this.$emit('canceled');
  }
}
