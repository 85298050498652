import Model from './Model';
import IUser from './interfaces/IUser';

export default class User extends Model implements IUser {

  static type = 'users';
  static roles: Array<{ name: string, id: string }> = [
    { name: 'Agente', id: 'agent' },
    { name: 'Tecnico', id: 'technician' },
    { name: 'Admin', id: 'admin' },
    { name: 'Amministrazione', id: 'executive' },
    { name: 'Direzione', id: 'manager' }
  ];

  email: string;
  firstName: string;
  externalCode: string;
  lastName: string;
  role: string;
  color: string;
  active: boolean;

  fullname(): string {
    return this.firstName + ' ' + this.lastName;
  }

  static getRoleLabel(role: string): string | undefined {
    return this.roles.find(o => o.id === role)?.name;
  }

  isOneOf(roles: string[]) {
    return roles.includes(this.role);
  }

  get isAdmin() {
    return this.role === 'admin';
  }

  get isManager() {
    return this.role === 'manager';
  }

  get isExecutive() {
    return this.role === 'executive';
  }

  get isAgent() {
    return this.role === 'agent';
  }

  get isTechnician() {
    return this.role === 'technician';
  }
}
