import Model from './Model';
import ICompany from './interfaces/ICompany';
import { Contact } from 'models';
import Address from './Address';

export default class Company extends Model implements ICompany {
  static type = 'companies';

  contact?: Contact;
  address?: Address;
  businessName?: string;
  fiscalCode?: string;
  vatCode?: string;
  phone?: string;
  pec?: string;
  email?: string;
}
