import { ResponseType } from 'axios';
import { AxiosConfigWithTag } from 'store/http';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class RequestsMixin extends Vue {

  $httpGet(url, tag, responseType: ResponseType = 'json') {
    const axiosConfig: AxiosConfigWithTag = {
      tag: tag,
      url: url,
      method: 'GET',
      responseType
    };

    return this.$store.dispatch('http/request', axiosConfig);
  }

  public $httpPost(url, tag, data) {
    return this.$store.dispatch('http/request', {
      tag: tag,
      url: url,
      data: data,
      method: 'POST'
    });
  }

  public $httpPut(url, tag, data) {
    return this.$store.dispatch('http/request', {
      tag: tag,
      url: url,
      data: data,
      method: 'PUT'
    });
  }

  public $httpDelete(url, tag) {
    return this.$store.dispatch('http/request', {
      tag: tag,
      url: url,
      method: 'DELETE'
    });
  }
}
