const routes = [
  {
    path: '/styleguide', component: () => import('views/Styleguide/Index.vue'), meta: { role: ['admin'] },
    children: [
      {
        path: '',
        name: 'styleguide-typography',
        component: () => import('views/Styleguide/Typography.vue'),
      },
      {
        path: 'buttons',
        name: 'styleguide-buttons',
        component: () => import('views/Styleguide/Buttons.vue'),
      },
      {
        path: 'labels',
        name: 'styleguide-labels',
        component: () => import('views/Styleguide/Labels.vue'),
      },
      {
        path: 'tables',
        name: 'styleguide-tables',
        component: () => import('views/Styleguide/Tables.vue'),
      },
      {
        path: 'form',
        name: 'styleguide-form',
        component: () => import('views/Styleguide/Form.vue'),
      }
    ]
  },
];

export default routes;
