



















import { Component, Prop, Mixins } from 'vue-property-decorator';
import ModelSelect from 'components/ModelSelect.vue';
import { RequestData } from 'store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import PlaceParametersMixin from 'mixins/http/PlaceParametersMixin';
import { ContractDurationOption } from 'models';
import ObjectPathMixin from 'mixins/ObjectPathMixin';

@Component({ components: { ModelSelect } })
export default class ContractDurationOptionSelect extends Mixins(PlaceParametersMixin, ObjectPathMixin) {
  static readonly getContractDurationOptionsTag = 'get_contract_duration_options';
  @RegisterHttp(ContractDurationOptionSelect.getContractDurationOptionsTag) http: RequestData;

  @Prop() idSelected?: string | number;
  @Prop() params;
  @Prop() dataKeys?: string;
  @Prop() classes?: string;
  @Prop({ default: false }) disabled?: boolean;
  @Prop() actualDuration?: string;
  @Prop() formClass?: string;

  items: any = null;

  get queryParams() {
    return {per: 1000, ...this.params};
  }

  get types() {
    return ContractDurationOption.types;
  }

  mounted() {
    this.loadItems();
  }

  outputParams(data) {
   return this.outputObject(data, this.dataKeys);
  }

  onItemSelected(data) {
    this.$emit('change', {
      contractDurationNumber: data.number,
      contractDurationTypeName: data.typeName
    });
  }

  loadItems() {
    this.getPlaceParameters(ContractDurationOption, this.queryParams, ContractDurationOptionSelect.getContractDurationOptionsTag).then(
    resp => {
        this.items = resp.filter((element) => element.disabled === false).map((element) => {
          return {
            id: element.id,
            name: `${element.number} ${ContractDurationOption.types.filter((type) => type.id === element.typeName)[0].name}`,
            number: element.number,
            typeName: element.typeName
          }
        });
      }
    );
  }

}
