





























































































































































import axios from 'axios';
import Icon from 'components/Icon.vue';
import ClickOutside from 'directives/ClickOutside';
import PlaceAuthorizationMixin from 'mixins/PlaceAuthorizationMixin';
import PlacesMixin from 'mixins/http/PlacesMixin';
import { Place, User } from 'models';
import { RequestData } from 'store/types/RequestStore';
import { RegisterHttp } from 'utils/Decorators';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { downlodadFileFromArrayBuffer } from 'models/DownloadHelper'

@Component({
  components: {
    Icon,
  },
  directives: {
    ClickOutside
  }
})
export default class PlaceEdit extends Mixins(
  PlaceAuthorizationMixin,
  PlacesMixin
) {
  @Getter currentUser?: User;
  @Getter currentPlace?: Place;

  dropdownOpen: boolean = false;

  @Prop() disabled?: boolean;

  static readonly updatePlaceTag = 'update_place';
  @RegisterHttp(PlaceEdit.updatePlaceTag) updatePlaceRequest: RequestData;

  static readonly assignAgentTag = 'assign_agent';
  @RegisterHttp(PlaceEdit.assignAgentTag) assignAgentRequest: RequestData;

  static readonly downloadReportTag = 'download_report';
  @RegisterHttp(PlaceEdit.downloadReportTag) downloadReportRequest: RequestData;

  static readonly shareToAgentTag = 'share_to_agent';
  @RegisterHttp(PlaceEdit.shareToAgentTag) shareToAgentRequest: RequestData;

  @Action('http/register') registerCall;
  static readonly downloadContractPreviewTag = 'download_contract_preview';

  emitEvent(name: string) {
    this.$emit(name);
  }

  onOutsideClick() {
    if (this.dropdownOpen) this.dropdownOpen = false;
  }

  get downloadContractPreviewTag(){
    return `${PlaceEdit.downloadContractPreviewTag}_${this.currentPlace?.id}`;
  }

  get callAction() {
    return `tel:${this.currentPlace?.company?.phone}`;
  }

  get mapsAction() {
    return `http://maps.google.com/?q=${this.currentPlace.address.fullAddress}`;
  }

  get ownsPlace() {
    return this.currentPlace?.agent?.id === this.currentUser.id;
  }

  get canDiscard() {
    return this.canDiscardPlace(this.currentUser, this.currentPlace);
  }

  get canCandidate() {
     return this.canCandidatePlace(this.currentUser, this.currentPlace);
   }

  get canComplete() {
    return this.canCompletePlace(this.currentUser, this.currentPlace);
  }

  get updatePlaceTag() {
    return PlaceEdit.updatePlaceTag;
  }

  get canDownload() {
    return this.canDownloadPlaceReport(this.currentUser, this.currentPlace);
  }

  get canDownloadContractPreview() {
    return this.currentPlace?.version === 2 
      && this.currentUser?.isManager
      && (!this.currentPlace?.isDraft || !this.currentPlace?.isPending || !this.currentPlace?.isRejected || !this.currentPlace?.isLead);
  }

  openDocument(link) {
      window.open(link, '_blank');
  }

  get canShare() {
    return this.canSharePlace(this.currentUser, this.currentPlace);
  }

  get placeStatus() {
    return Place.getStatusLabel(this.currentPlace.status);
  }

  get canReject() {
    return this.canRejectPlace(this.currentUser, this.currentPlace);
  }

   get canAccept() {
    return this.canAcceptPlace(this.currentUser, this.currentPlace);
  }

  get canDelete() {
    return this.canDeletePlace(this.currentUser, this.currentPlace);
  }

  get canAssign() {
    return this.canAssignPlace(this.currentUser, this.currentPlace);
  }

  get canSchedule() {
    return this.canSchedulePlace(this.currentUser, this.currentPlace);
  }

  download() {
    axios({
      method: 'get',
      url: this.$urlFor('download_place_report_api_v1_pdf', {id: this.currentPlace.id}),
      responseType: 'arraybuffer',
    }).then((response) => {
      let blob = new Blob([response.data], { type: 'application/pdf' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Scheda_Cliente_${this.currentPlace.name}.pdf`;
      link.click();
    });
  }

  getContractPdfPreview(){
    this.registerCall(this.downloadContractPreviewTag);
    const promise = this.downloadContractPreview(this.currentPlace?.id, this.downloadContractPreviewTag);
    const pdfName = `Proposta di contratto di ${Place.contractTypes.filter((el) => el.id === this.currentPlace?.order?.newContractType)[0]?.name} - ${this.currentPlace.name}`;
    downlodadFileFromArrayBuffer(promise, pdfName);
  }

  get canNotify() {
    return this.canNotifyPlace(this.currentUser, this.currentPlace);
  }

  get canPromote() {
    return this.canPromotePlace(this.currentUser, this.currentPlace);
  }

  get canReassign() {
    return this.canReassignPlace(this.currentUser, this.currentPlace);
  }
}
