






















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import InlineEditor from 'components/InlineEditor.vue';
import OrderMixins from 'mixins/http/OrdersMixin';
import { RegisterHttp } from 'utils/Decorators';
import { RequestData } from 'store/types/RequestStore';
import Icon from 'components/Icon.vue';
import IOrderAccessory from '../../models/interfaces/IOrderAccessory';

@Component({ components: { InlineEditor, Icon } })
export default class OrderAccessoryItem extends Mixins(OrderMixins) {
  static readonly deleteOrderAccessoryTag = 'delete_order_accessory';
  static readonly updateOrderAccessoryTag = 'update_order_accessory';

  @Prop({ required: true })
  item: IOrderAccessory;

  @Prop({ default: false }) disabled?: boolean;

  @RegisterHttp(OrderAccessoryItem.deleteOrderAccessoryTag)
  deleteRequest: RequestData;
  @RegisterHttp(OrderAccessoryItem.updateOrderAccessoryTag)
  updateRequest: RequestData;

  private data = this.item;

  @Watch('item')
  onParameterValueChanged(val, oldVal) {
    this.data = val;
  }

  onParameterChanged(value) {
    let data = {
      id: this.data.id,
      ...value
    };

    this.updateOrderAccessory(data, OrderAccessoryItem.updateOrderAccessoryTag).then(
      resp => {
        this.data = resp;
      }
    );
  }

  onDeleteClicked() {
    this.destroyOrderAccessory(
      this.data.id,
      OrderAccessoryItem.deleteOrderAccessoryTag
    ).then(
      resp => {
        this.$emit('deleted', resp);
      }
    );
  }
}
