

















































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {Getter, Action} from 'vuex-class';
import InlineEditor, { IDataFormatter } from 'components/InlineEditor.vue';
import PaymentMethodSelect from 'components/PaymentMethod/Select.vue';
import ContractTypeSelect from 'components/ContractType/Select.vue';
import BlendSelect from 'components/Blend/Select.vue';
import OrderGear from 'components/OrderGear/Item.vue';
import OrderAccessory from 'components/OrderAccessory/Item.vue';
import CreateOrderGear from 'components/OrderGear/Create.vue';
import CreateOrderAccessory from 'components/OrderAccessory/Create.vue';
import CreateOrderSpecialCondition from 'components/OrderSpecialCondition/Create.vue';
import DatePicker from 'vuejs-datepicker';
const moment = require('moment');
import {ContractDurationOption, Place, User} from 'models';
import { showToast } from 'utils/Toast';
import OrderSpecialCondition from 'components/OrderSpecialCondition/Item.vue';
import ContractDurationOptionSelect from 'components/ContractDurationOption/Select.vue';
import Icon from 'components/Icon.vue';

@Component({
  components: {
    Icon,
    InlineEditor,
    PaymentMethodSelect,
    ContractTypeSelect,
    DatePicker,
    BlendSelect,
    OrderGear,
    CreateOrderGear,
    OrderAccessory,
    OrderSpecialCondition,
    CreateOrderAccessory,
    CreateOrderSpecialCondition,
    ContractDurationOptionSelect
  }
})
export default class PlaceOrder extends Vue {
  @Prop({ required: true })
  place: Place;
  isEditing: boolean = false;

  private dateFormatter: IDataFormatter = {
    fromString(value) {
      return value;
    },
    toString(value) {
      return moment(value).format('DD/MM/YYYY');
    }
  };

   private priceFormatter: IDataFormatter = {
    fromString(value) {
      return value;
    },
    toString(value) {
      return new Intl.NumberFormat('it-IT').format(value);
    }
  };

  @Prop({default: false})
  disabled: boolean;

  @Action setCurrentPlace;

  @Getter currentUser?: User;

  onBlendSelected(data) {
    this.onDataUpdated({order: {
      blend_id: data.id,
      price: data.price
    }});
  }

  showOptionalField(value) {
    return (this.currentUser.isExecutive && value) || !this.currentUser.isExecutive;
  }

  get contractTypes() {
    return [...Place.contractTypes];
  }

  get date() {
    if (this.place.order.installationDate) return moment(this.place.order.installationDate).format('YYYY-MM-DD');
  }

  set date(date) {
    if (date) {
      this.place.order.installationDate = moment(date + ' ' + (this.time || '00:00'));
      if (this.time) {
        this.onDataUpdated({order: { installation_date: this.place.order.installationDate }});
      }
    }
  }

  get time() {
    if (this.place.order.installationDate) return moment(this.place.order.installationDate).format('HH:mm');
  }

  get actualDuration() {
    if (!this.place.order.contractDurationTypeName || !this.place.order.contractDurationNumber) {
      return ''
    }
    return this.place.order.contractDurationNumber + ' ' +
      (this.getTranslatedDurationTypeName(this.place.order.contractDurationTypeName));
  }

  set time(time) {

     if (time) {
      let value = moment(this.date || new Date());
      const timeMoment = moment(time, 'HH:mm');
      value.set({
        hour: timeMoment.get('hour'),
        minutes: timeMoment.get('minutes')
      });
      this.place.order.installationDate = value.toISOString();
    }
  }

  onDateChanged() {
    if (this.date) {
        this.onDataUpdated({order: { installation_date: this.place.order.installationDate }});
    }
  }

  onDataUpdated(data) {
    this.stopDurationEditing()
    this.$emit('updated', data);
  }

  onContractDurationOptionChanged({ contractDurationNumber, contractDurationTypeName }) {
    this.onDataUpdated({
      order: {
        contract_duration_number: contractDurationNumber,
        contract_duration_type_name: contractDurationTypeName
      }
    });
  }

  onOrderGearCreated(item) {
    this.place.order.orderGears.push(item);
    showToast('Attrezzatura aggiunta con successo!');
  }
   onOrderGearDeleted(item) {
    this.place.order.orderGears = this.place.order.orderGears.filter(o => o.id !== item.id);
    showToast('Attrezzatura eliminata con successo!');
  }

  onOrderAccessoryCreated(item) {
    this.place.order.orderAccessories.push(item);
    showToast('Accessorio aggiunta con successo!');
  }

  onOrderSpecialConditionCreated(item){
    this.place.order.orderSpecialConditions.push(item);
    showToast('Condizione speciale aggiunta con successo!');
  }

  onOrderGearUpdated(item) {
    let orderGears = this.place.order.orderGears;
    const index = orderGears.findIndex(o => o.id === item.id);
    this.$set(orderGears, index, item );
    this.place.order.orderGears = orderGears;
    this.setCurrentPlace(this.place.clone());
  }

  onOrderAccessoryDeleted(item) {
    this.place.order.orderAccessories = this.place.order.orderAccessories.filter(o => o.id !== item.id);
    showToast('Accessorio eliminata con successo!');
  }

  onOrderSpecialConditionDeleted(item) {
    this.place.order.orderSpecialConditions = this.place.order.orderSpecialConditions.filter(o => o.id !== item.id);
    showToast('Condizione speciale eliminata con successo!');
  }

  get showEmptyGears() {
    return  !this.place?.order?.orderGears?.length;
  }

  get canShowConsignee() {
    return this.place.consignee?.id;
  }

  get canShowInstallationInfo() {
    return !this.currentUser.isTechnician;
  }

  get showEmptyAccessories() {
    return  !this.place?.order?.orderAccessories?.length;
  }

  get showEmptySpecialConditions(){
    return !this.place?.order?.orderSpecialConditions?.length;
  }

  get canSetContractTypology(){
    return ((this.place.isOneOf(['customer', 'scheduled', 'completed']) && this.place.version === 1) || this.place.version === 2);
  }

  startDurationEditing() {
    this.isEditing = true;
    this.$nextTick(() => {
      (this.$refs.inlineEditor as InlineEditor).enableEditing();
    });
  }

  stopDurationEditing() {
    this.isEditing = false;
  }

  getTranslatedDurationTypeName(typeName) {
    const translation = ContractDurationOption.types.filter((el) => el.id === typeName);
    if (translation.length !== 1) {
      return typeName;
    }
    return translation[0].name;
  }
}

